/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.NotificationList {
    position: fixed;
    z-index: 1000;
    right: 30px;
    max-width: 550px;
    list-style: none;
    top: 0;

    @include after-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
    }

    @include mobile {
        overflow: hidden;
        max-width: 100vw;
        width: 100%;
        right: 0;
    }
}
