/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --wishlist-heart-size: 12px;
    --wishlist-heart-background: #{$default-secondary-light-color};
    --wishlist-heart-color-pdp: #f2f2f2;
}

.ProductWishlistButton {
    --loader-scale: .6;
    --heartColor: white;
    vertical-align: middle;
    display: inline-block;

    @include mobile {
        margin-left: 1.26rem;
    }

    @include tablet-portrait {
        margin-top: 1.2rem;
    }

    &-Button,
    &-Button-Wishlist {
        --button-padding: 0;
        --button-hover-background: transparent;
        border-color: transparent;
        background-color: transparent;
    }

    &-Button {
        height: 31px;
        width: 34px;

        &-Wishlist {
            width: 20px;
            height: 20px;
        }
    }

    &-Button:hover,
    &-Button:active,
    &-Button:focus,
    &-Button-Wishlist:hover,
    &-Button-Wishlist:active,
    &-Button-Wishlist:focus{
        border-color: transparent !important;
    }

    .Icon {
        fill: var(--heartColor);
        stroke-width: 20px;
        stroke: red;

        &:hover {
            --heartColor: red;
        }

        &-InWishlist {
            fill: var(--heartColor);
            stroke-width: 20px;
            stroke: red;
            --heartColor: red;
        }
    }

    &-AttributeWishlist {
        height: 100%;
        width: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url('../#{$icons-path}//heart_icon.png');
    }

    &-ProductActionsWishlist {
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('../#{$icons-path}//icon_wishlist.svg');
    }

    &-Heart {
        transform: rotate(-45deg);
        bottom: 0;
        top: 1px;
        margin: auto;

        &,
        &::after,
        &::before {
            height: var(--wishlist-heart-size);
            width: var(--wishlist-heart-size);
            background-color: var(--wishlist-heart-background);
        }

        &::after,
        &::before {
            content: '';
            border-radius: 50%;
            position: absolute;
        }

        &::before {
            top: calc(var(--wishlist-heart-size) / -2);
            right: 0;
        }

        &::after {
            right: calc(var(--wishlist-heart-size) / -2);
            top: 0;
        }
    }

}
