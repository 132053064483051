/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

$pathToSocialLogo: './../../../public/assets/images/login-signup-block/';

.MyAccountMySocialNetworks {
    max-width: 330px;

    @include mobile {
        max-width: initial;
    }

    &-Title {
        font-size: var(--color-my-account-social-title-font-size);
        font-weight: var(--color-my-account-social-title-font-weight);
        line-height: 1.43;
        margin: 25px 0 10px;
    }

    &-Content {
        display: flex;
        flex-direction: column;
    }

    &-ToggleWrapper {
        cursor: pointer;
    }

    &-Item {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 16px;
        padding: 0;

        &:last-of-type {
            margin-bottom: 0;
        }

        .Toggle {
            pointer-events: none;

            &-Label {
                margin: 0 20px 0 0;
            }

            label {
                order: 1;
            }
        }
    }

    .Button-Social {
        border: 0;
        height: 35px;
        width: 80px;
        background: {
            color: var(--primary-base-color);
            position: center;
            repeat: no-repeat;
            size: 24px;
        }

        @include desktop {
            background-size: 26px;
            height: 50px;
            width: 112px;
        }

        &_type {
            &_facebook {
                background: {
                    image: url($pathToSocialLogo + 'facebook.svg');
                    size: 30px;
                }

                @include desktop {
                    background-size: 36px;
                }
            }

            &_google {
                background: {
                    image: url($pathToSocialLogo + 'google.svg');
                }

                @include desktop {
                    background-size: 30px;
                }
            }

            &_linkedin {
                background: {
                    image: url($pathToSocialLogo + 'linked-in.svg');
                    size: 20px;
                }

                @include desktop {
                    background-size: 24px;
                }
            }

            &_paypal {
                background: {
                    image: url($pathToSocialLogo + 'paypal.svg');
                    size: 20px;
                }

                @include desktop {
                    background-size: 21px;
                }
            }

            &_twitter {
                background: {
                    image: url($pathToSocialLogo + 'twitter.svg');
                    size: 34px;
                }

                @include desktop {
                    background-size: 40px;
                }
            }

            &_instagram {
                background: {
                    image: url($pathToSocialLogo + 'instagram.svg');
                }

                @include desktop {
                    background-size: 28px;
                }
            }

            &_twitch {
                background: {
                    image: url($pathToSocialLogo + 'twitch.svg');
                    size: 20px;
                }

                @include desktop {
                    background-size: 26px;
                }
            }

            &_amazon {
                background: {
                    image: url('./../../../public/assets/images/login-signup-block/amazon.svg');
                }

                @include desktop {
                    background-size: 30px;
                }
            }
        }
    }
}
