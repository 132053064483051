/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Tooltip {
    display: inline;

    &-InfoIcon {
        display: inline-block;
        height: 15px;
        width: 15px;
        left: 6px;
        top: 3px;
        cursor: help;
    }

    &-Wrapper {
        display: none;
        position: absolute;
        z-index: 999;
        bottom: 10px;
        left: 15px;
        min-width: 250px;
        background-color: #fff;
        border-radius: 8px;
        -moz-box-shadow: 3px 3px 3px #adadad;
        -webkit-box-shadow: 3px 3px 3px #adadad;
        box-shadow: 3px 3px 3px #adadad;
        text-align: left;
        letter-spacing: normal;
        font-family: var(--product-actions-font-helvetica-neue);
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.42857143;
        padding: 15px 20px;

        &_isShowing {
            display: block;
        }

        @include desktop {
            &:hover {
                display: block;
            }
        }

        @include mobile {
            bottom: 20px;
            left: unset;
            right: 50%;
            transform: translateX(50%);
        }
    }

    &-InfoIcon:hover~&-Wrapper {
        @include desktop {
            display: block;
        }
    }

    &-CloseIcon {
        @include desktop {
            display: none;
        }

        position: absolute;
        right: 5px;
        top: 5px;
    }

    &-Element:hover {

        .Tooltip-Wrapper {
            @include desktop {
                display: block;
            }
            left: -10px;
            bottom: 20px;
            padding: 5px 10px;
            min-width: unset;
        }
    }

    &-Title {
        color: #000000;
        font-family: Lato;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 17px;
        margin: 0;
    }
}
