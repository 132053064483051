/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.FieldSelect {
    &-Option {
        &_disabled {
            pointer-events: none;
            text-decoration: line-through;
        }

        &_isActive {
            background-color: var(--select-option-hover-background);
        }
    }

    &-Select {
        border-color: black;
        &_isExpanded {
            border-color: black;
            box-shadow: 0 0 0 1px black;
        }
    }
}
