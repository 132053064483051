/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductPreviewAndQuantity {
    &-QtyReview {
        display: flex;
        background-color: #F2F2F2;
        padding: 2.4rem;
        width: auto;
    }

    &-ProductImage {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 3px;
    }

    &-ProductDetails {
        margin-left: 10px;

        h3 {
            margin: 0;
            color: #888888;
            font-family: Lato;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 24px;

            &:first-child {
                color: #000000;
                font-weight: bold;
            }
        }
    }

    &-QtyWrapper {
        margin-left: auto;
    }

    &-QtyInput {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        height: 40px;
        margin-top: 16px;
        border: 1px solid var(--product-actions-input-border-color);
        border-radius: 4px;

        input {
            width: 36px;
            height: 17px;
            background: transparent;
            border: 0;
            font-weight: 400;
            text-align: center;
            padding: 0;
            order: 2;
            font-size: 1.6rem;
            margin: 0;

            &:focus,
            :hover {
                border: 0;
            }
        }

        button {
            position: relative;
            left: unset;
            right: unset;
            top: 0;

            &:first-of-type {
                order: 3;
            }

            &:last-of-type {
                order: 1;
            }
        }
    }

    &-BoxCoverage {
        top: 5px;
        color: #888888;
        font-family: Lato;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 13px;
    }
}
