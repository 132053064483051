/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.SearchField {
    &-Wrapper {
        grid-area: search;
        flex-grow: 2;
    }

    &-ClearForm {
        visibility: hidden;
        position: absolute;

        &_isVisible {
            visibility: visible;
            position: absolute;
            right: 75px;
            padding-top: 13px;
            text-decoration: underline;

            @include mobile {
                right: 60px;
                padding-top: 16px;
            }
        }
    }

    &-MobileSearchWrapper {
        display: flex;
    }

    &-MobileSearch {
        display: flex;
        align-items: center;
        padding-top: 4px;
    }

    &-MobileSearchButton {
        width: 49px;
        height: 25px;
    }

    &-CancelButton {
        margin-left: 22px;
        color: #000000;
        font-family: Lato;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
    }

    &-SearchIcon {
        color: #000000;
    }

    &-MobileSearchOverlay {
        display: flex;
        flex-flow: column;
        flex: 1 1 auto;
        height: 100%;
        width: 100%;
        position: fixed;
        top: calc(var(--demo-notice-height) + var(--header-top-menu-height));
        left: 0;
        z-index: 10;

        background-color: rgba(116, 115, 115, 0.95);
    }

    &-MobileSearchWrapper {
        display: flex;
        background-color: white;
        width: 100%;
        padding: 20px;
    }
}
