/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --search-bar-max-width: 1000px;
    --search-bar-width: 270px;
    --search-clear-background: var(--secondary-base-color);
    --search-field-background: #f2f2f2;
    --search-field-border-color: var(--primary-divider-color);
    --search-field-color: var(--secondary-base-color);
    --search-field-visible-background: #fff;
    --search-input-desktop-border-color: #cac5c5;
    --search-input-desktop-border-color-hover: var(--primary-base-color);
    --search-input-input-background: var(--input-background-color);
    --search-input-input-color: #{$black};
    --search-input-placeholder-color: var(--secondary-dark-color);
    --search-input-button-background: var(--primary-base-color);
    --search-input-button-background-hover: var(--primary-dark-color);
}

.SearchField {
    padding: 10px 40px 10px;

    &-SubmitButton {
        width: 40px;
        height: 40px;
        background-color: var(--search-field-background);
        transition: background-color 0.5s ease;
        display: inline-block;
        right: 4px;
        top: 4px;
        position: absolute;
        border-radius: 3px;

        .Icon {
            fill: var(--primary-base-color);
        }

        &_isActive {
            background-color: var(--primary-base-color);

            .Icon {
                fill: white;
            }
        }
    }

    &-Form {
        display: flex;
        max-width: 811px;

        @include desktop {
            width: 100%;
            float: right;
        }

        input {
            border-radius: 5px;
            height: 48px;

            &:focus {
                border: 0;
            }
        }
    }

    &-Fieldset {
        display: flex;
        flex-wrap: nowrap;

        &_isLoading {
            opacity: .7;
            pointer-events: none;
        }

        .Field {
            width: 100%;
            margin-top: 0;

            &-Message {
                position: absolute;
            }

            input {
                transition-property: opacity, background-color;
                transition-duration: 200ms;
                background-color: transparent;
                border: 1px solid var(--search-input-desktop-border-color);
                border-right: none;
                border-radius: 4px 0px 0px 4px;
                font-size: 1.4rem;
                max-width: 100%;
                min-width: 0;
                opacity: 1;
                width: 100%;
                padding: 2rem;

                @include before-desktop {
                    max-width: 100%;
                }

                &:focus,
                &:active {
                    --input-color: var(--search-field-color);

                    @include desktop {
                        border: 1px solid var(--search-input-desktop-border-color-hover);
                        border-right: none;
                    }
                }
            }

            &_hasError {
                input {
                    border: 1px solid var(--primary-error-color);
                    border-right: 0;
                }
            }
        }
    }

    &-Fields {
        margin-bottom: 0;
        width: 100%;
    }

    &-Button {
        width: 65px;
        background-color: var(--search-input-button-background);
        border-color: var(--search-input-button-background);
        border-radius: 0px 4px 4px 0px;
        &:focus,
        &:hover {
            border-color: var(--search-input-button-background-hover);
            background-color: var(--search-input-button-background-hover);
        }

        &:disabled {
            opacity: 1;
            border-color: var(--search-input-desktop-border-color);
            background-color: var(--search-input-desktop-border-color);
        }

        .Icon {
            top: 3px;
        }
    }

    @include before-desktop {
        flex-grow: 1;
        grid-area: search;
        padding: 10px 0 0 0;
    }

    @include desktop {
        width: 100%;
        max-width: 720px;
        text-align: right;
    }

    &_isVisible {
        opacity: 1;
        pointer-events: all;
    }

    &_isActive {
        --search-bar-width: 27vw;
        --search-field-border-color: var(--primary-light-color);
    }

    &-Wrapper {
        @include desktop {
            margin-right: 1.2rem;
            width: 100%;
        }
    }

    &-Form {
        input {
            width: 100%;
            background-color: #f2f2f2;
        }
    }

    &-Placeholder {
        font-size: 1.6rem;
        height: min-content;
        left: calc(50% - 5px);
        padding-left: 20px;
        pointer-events: none;
        position: absolute;
        top: 5px;
        transform: translate3d(-50%, 0, 0);
        visibility: hidden;
        width: min-content;

        @include search-icon;

        &::before {
            bottom: 0;
            left: 0;
            margin: auto;
            top: 0;
            transform: translateY(-2px);
        }

        &_isActive {
            left: 0;
            opacity: .7;
            padding-left: 0;
            transform: translate3d(10px, 0, 0);

            &::before {
                opacity: 0;
            }
        }

        &_isPlaceholderVisible {
            visibility: visible;
        }
    }

    &-SearchIcon,
    &-CloseIcon {
        cursor: pointer;

        @include desktop {
            height: 24px;
            width: 16px;
        }
    }

    &-SearchIcon {
        @include search-icon;

        &::before {
            box-shadow:
                6px 6px 0 -7px var(--header-color),
                7px 7px 0 -7px var(--header-color),
                8px 8px 0 -7px var(--header-color),
                9px 9px 0 -7px var(--header-color),
                10px 10px 0 -7px var(--header-color),
                11px 11px 0 -7px var(--header-color);
            height: calc(16px - var(--header-icon-stroke-width) * 2);
            left: 0;
            top: 2px;
            width: calc(16px - var(--header-icon-stroke-width) * 2);
        }
    }

    &-CloseIcon {
        @include close-button;

        &::before,
        &::after {
            height: 22px;
            left: 8px;
            top: 2px;
            width: 2px;
        }
    }

    &-SearchWrapper {
        background: var(--search-field-visible-background);
        height: 100%;
        width: 100%;
        max-width: 720px;
        position: fixed;
        right: 0;
        top: calc(var(--header-total-height) + 3px);
        transform: translateX(100%);
        transition: transform 300ms ease-in;
        width: 100%;
        will-change: transform;
        border-left: 1px solid var(--primary-divider-color);

        &_isVisible {
            transform: translateX(0);
        }
    }

    &-SearchInnerWrapper {
        .SearchField-SearchIcon {
            left: 4.2rem;
            position: absolute;
            top: 4.8rem;

            @include mobile {
                left: 4.9rem;
                top: 5.6rem;
            }
        }
    }

    &-Input:focus + .SearchField-SubmitButton {
        background-color: var(--primary-base-color);

        .Icon {
            fill: white;
        }
    }

    &-Input {
        &_isActive + .SearchField-SubmitButton {
            background-color: var(--primary-base-color);

            .Icon {
                fill: white;
            }
        }
    }
}
