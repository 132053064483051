/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
*/
:root {
    --cms-font-lato: 'Lato', sans-serif;
    --cms-submit-button-color: #ed4f36;
    --form-error-color: #dc6d6d;
}

.CmsPage {
    margin-top: 0px;
    margin-bottom: 0;

    a+a {
        margin: none;
    }

    &_isBreadcrumbsHidden {
        margin-top: calc(var(--header-total-height) + 1.2rem);

        @include mobile {
            margin-top: calc(var(--header-total-height) + 1.4rem);
        }
    }

    &-AdblockWarning {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        width: 300px;
        margin: auto;
        padding: 20px 0;
    }

    #UpdateQuoteBoxQty {
        cursor: pointer;
        font-size: 1.4rem;
        font-family: var(--product-actions-font-lato-regular);
        text-transform: none;
        font-weight: 400;
        color: var(--primary-base-color);
        padding: 1.4rem 2rem 1.4rem 0;
        display: flex;
    }

    &-Content {
        &_isHomePage {
            @include mobile {
                min-height: 3800px;
            }
        }

        ul,
        ol {
            margin-top: 1rem;
        }

        font-family: var(--cms-font-lato);
        position: unset;
        .RenderWhenVisible {
            position: unset;
        }
        > div {
            position: unset;
            > .row {
                position: unset;
            }
        }

        .InspirationProductCard{
            &-Content{
                position: absolute;
            }
        }
        .category-view {
            position: relative;
            text-align: center;
            max-width: 1200px;
            margin: auto;
            overflow: hidden;
            border-bottom: 1px solid rgb(204, 204, 204);
        }

        .category-image {
            height: 300px;
            position: relative;
            overflow: hidden;

            img {
                width: 1200px;
                height: 300px;
                object-fit: fill !important;
            }
        }

        .page-title-wrapper {
            position: absolute;
            top: 100px;
            left: 0px;
            height: 100%;
            max-width: 100%;
            background: 0px 0px;
            text-align: center;
            width: 100%;

            @include mobile {
                top: 40px;
            }

            #page-title-heading {
                color: rgb(255, 255, 255);
                text-transform: uppercase;
                font-size: 44px;
                font-weight: 600;
                line-height: 60px;
                text-align: center;
            }
        }

        .category-description {
            text-align: center;
            font-size: 2.8rem;
            font-weight: 600;
            text-transform: uppercase;
        }

        .container-fluid {
            p, ul {
                color: rgb(127, 127, 127);
                font-size: 16px;
                line-height: 30px;
                padding: 10px;
            }

            p {
                strong {
                    color: black;
                }
            }
        }

        .tan-section {
            .row {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                p {
                    border-bottom: 1px solid #e7e3e3;
                }
            }

            .col-xs-6 {
                min-width: 200px;
                width: 33%;
                padding: 0px 10px;

                @include mobile {
                    width: 100%;
                }

                h5 {
                    color: rgb(229, 63, 38);
                    font-size: 24px;
                    font-weight: 600;
                }
            }
        }
        .categories {
            .category {
                width: 33.333%;
                display: inline-block;
                padding: .75rem;
                @include mobile {
                    width: 50%;

                }
            }
        }
    }

    &-Wrapper {
        grid-template-columns: auto;

        padding: {
            left: 1.2rem;
            right: 1.2rem;
        };

        margin: {
            top: 0px;
            left: auto;
            right: auto;
        };

        @include mobile {
            padding: {
                left: 1.4rem;
                right: 1.4rem;
            }
            margin: {
                top: 0px;
            }
        }

        &_page_width {
            &_full {
                max-width: 100%;
                padding: {
                    left: 0;
                    right: 0;
                };
            }
        }
        max-width: var(--content-wrapper-width);
    }

    &-Heading {
        margin-bottom: 1.8rem;

        @include mobile {
            margin-bottom: 2.1rem;
            display: none;
        }
    }

    &-SectionPlaceholder {
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        margin: 0 0 10px;
        padding-bottom: 30%;

        @include mobile {
            padding-bottom: 60%;
        }

        &:first-child {
            padding-bottom: 40%;

            @include mobile {
                padding-bottom: 100%;
            }
        }
    }

    .TextPlaceholder {
        margin-bottom: 2.16rem;
        display: block;

        @include mobile {
            margin-bottom: 2.52rem;
        }
    }

    h2,
    h3 :not(.NoFontSizeChange){
        @include mobile {
            font-size: 1.4rem;
        }
    }
    .gallery-container{
        h2.text-center{
            text-align: center;
        }
        h2.page-main-title{
            font-size: 30px;
            font-weight: 500;
            color: #333;
        }
        .gallery-sub-title{
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: #1d2d44;
            display: inline-block;
            margin-bottom: 10px;
            line-height: 20px;
        }
    }

    .columns {
        margin: 0;
        padding: 0;

        &.Category {
            display: grid;
            grid-template-columns: repeat(5, minmax(0, 1fr));

            @include mobile {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
        }

    }

    .card {
        height: 100%;
        display: flex;
        flex-direction: column;

        &-content {
            flex-grow: 1;
        }
    }

    .Education-Wrapper {
        padding: 15px 0;
        display: grid;
        place-items: center;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        column-gap: 30px;

        @include mobile {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    .Education-Card {
        width: 100%;
        height: 100%;
    }

    #SampleCms {
        h1 {
            font-size: 6rem;

            @include mobile {
                font-size: 4rem;
            }
        }
    }

    #TileBackground,
    #SampleBackground {
        img {
            object-fit: cover;
            @include tablet {
                top: 0px;
                object-fit: cover;
            }
        }

        .Image {
            height: inherit;
        }

        .Image_ratio_square {
            padding-bottom: inherit;
        }
    }

    #dfwid-buffer {
        .dfwid-label {
            position: relative;
            z-index: 10;
            min-width: auto;
            font-size: 14px;
            font-weight: 400;
            width: fit-content;
            padding: .2em .6em .3em;
            background-color: white;
        }

        .dfwid-label--radio {
            background-color: transparent;
            top: 0;
        }

        @include desktop {
            .dfwid-submit-button {
                padding: 15px 30px;
            }
        }

        .dfwid-submit-button {
            margin: 0;
            width: auto;
            font-weight: 500;
        }

        .dfwid-main {
            padding: 0;
        }

        input {
            padding: 13px 20px;
        }

        .drip-errors:not(:empty):before {
            content: none;
        }

        .drip-errors:not(:empty):after {
            content: '!';
        }

        label.dfwid-label {
            font-family: #{$font-muli};
            font-size: 14px;
            font-weight: 400;
        }

        .drip-errors {
            font-family: #{$font-muli};
            font-size: 1.4rem;
            font-weight: 400;
            color: var(--form-error-color);
            -webkit-text-fill-color: var(--form-error-color);
        }

        .drip-dropdown-select {
            position: relative;
        }


        .drip-dropdown-select select {
            background-image: none;
        }

        .drip-dropdown-select:after {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            transform: translateY(-2px) rotate(
        45deg
        );
            pointer-events: none;
            position: absolute;
            right: 20px;
            bottom: 0;
            top: 0;
            margin: auto;
            border-style: solid;
            border-color: #000;
            border-width: 0 1px 1px 0;
        }

        .dfwid-submit-button {
            margin-top: 10px;
        }
    }

    .Table-Wrapper {
        overflow-x: unset;
    }

    .premium {
        margin: 30px 0;

        .hero {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));

            @include mobile {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .cta {
            padding: 15px;

            &-feature {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 25px 0;
            }

            &-title {
                color: #E53F24;
                text-align: center;
                font-family: Lato;
                font-size: 30px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.6px;
                max-width: 70%;
                margin-left: 15%;

                @include mobile {
                    max-width: none;
                    margin-left: 0;
                }
            }
    
            &-subtitle {
                color: #000;
                text-align: center;
                font-family: Lato;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.48px;
            }
    
            &-button {
                display: inline-flex;
                padding: 24px 48px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 8px;
                background: #E53F24;
                color: #fff;
            }

            &-icon {
                margin-bottom: 10px;
            }
    
            &-grid {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));

                @include mobile {
                    display: block;
                }
            }
    
            &-action {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
    
            &-text {
                color: #0A0A0A;
                text-align: center;
                font-family: Lato;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.4px;
            }

            &-image {
                @include mobile {
                    display: none;
                }
            }
        }

    }
    .faq {
        margin-top: 25px;
        padding: 0 115px 0 75px;

        @include mobile {
            padding: 0;
        }

        &-section {
            margin: 20px 0;
        }

        &-title {
            color: #E53F24;
            font-family: Lato;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 30px */
            letter-spacing: 0.4px;
        }

        &-text {
            color: #7F7F7F;
            font-family: Lato;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
            letter-spacing: 0.32px;
        }
    }

}
