/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.AddToCart {
    min-width: 14.4rem;
    vertical-align: middle;

    @include mobile {
        min-width: 16.8rem;
    }

    span {
        display: block;
        opacity: 1;
        transform: translateY(0);
        transition-property: transform, opacity;
        will-change: transform, opacity;
        transition-timing-function: ease-in;
        transition-duration: .25s;

        &:last-child {
            width: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            transform: translateY(0);
        }
    }

    &_isPlaceholder {
        height: 4.8rem;
        display: inline-block;
        padding: var(--button-padding);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        will-change: background-position;

        @include mobile {
            height: 5.6rem;
        }
    }

    &_isLoading {
        span {
            &:first-child {
                opacity: 0;
                transform: translateY(-50%);
            }

            &:last-child {
                opacity: 1;
                transform: translateY(-100%);
            }
        }

        &:active {
            span {
                &:last-child {
                    opacity: .9;
                    transform: translateY(-120%);
                }
            }
        }

    }

}

.AddSampleToCart {
    min-width: 14.4rem;
    vertical-align: middle;

    @include mobile {
        min-width: 16.8rem;
    }

    span {
        display: block;
        opacity: 1;
        transform: translateY(0);
        transition-property: transform, opacity;
        will-change: transform, opacity;
        transition-timing-function: ease-in;
        transition-duration: .25s;

        &:last-child {
            width: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            transform: translateY(0);
        }
    }

    &_isPlaceholder {
        height: 4.8rem;
        display: inline-block;
        padding: var(--button-padding);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        will-change: background-position;

        @include mobile {
            height: 5.6rem;
        }
    }

    &_isLoading {
        div {
            &:first-child {
                opacity: 0;
                transform: translateY(-50%);
            }
        }

        span {
            &:nth-child(2) {
                opacity: 0;
                transform: translateY(-50%);
            }

            &:last-child {
                opacity: 1;
                transform: translateY(-0%);
            }
        }

        &:active {
            span {
                &:last-child {
                    opacity: .9;
                    transform: translateY(-120%);
                }
            }
        }

    }

    &-AddIcon {
        width: 16px;
        height: 16px;
        border: 1px solid var(--primary-base-color);
        border-radius: 5px;
        display: inline-block;
        margin-right: 4px;

    }

    &-AddPlusIcon {        
        color: var(--primary-base-color);
        bottom: 2px;
    }

    &-QuoteBtn {
        margin-top: 10px;
        padding: 12px;
        border-radius: 8px;
        background-color: var(--primary-base-color);
        color: white;
    }
}
