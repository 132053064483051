/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$pathToFooterIcons: './../../../../../../pub/media/footer_assets/';

:root {
    --footer-height: calc(var(--footer-content-height) + var(--footer-copyright-height));
    --footer-content-height: 320px;
    --footer-copyright-height: 50px;
    --footer-background-color: #3b3b3b;
    --footer-font-color: #bbb;
}

// Used to improve CLS while main content is loading
// Ref: https://sepoy.atlassian.net/browse/ZFR-1445
.Breadcrumbs + .Footer {
    padding-top: 0;
}

.Footer {

    $column-count: 4;

    &-Title {
        color: white;
        font-size: 1.8rem;
        font-weight: 600;
        min-height: 20px;
        display: block;

        @include desktop {
            position: absolute;
        }
    }

    &-ColumnTitle {
        font-weight: bold;
    }

    &-NewsletterWrapper {
        display: grid;
        align-content: center;
        grid-template-columns: repeat(3, minmax(0, 1fr));

        @include mobile {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    &-Newsletter {
        grid-column: span 2 / span 2;
    }

    &-Contact {
        padding-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: end;
        min-height: 216px;

        @include mobile {
            align-items: start;
        }

        &Title {
            color: #fff;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.5px;
            line-height: 24px;
        }

        &Number {
            color: #fff;
            font-size: 19px;
            font-weight: 600;
            letter-spacing: 0.5px;
            line-height: 24px;
            text-decoration: underline;
        }

        &Hours {
            display: flex; 
            align-items: center;
            justify-content: space-evenly;
            color: #fff;
            text-align: right;
            
            p {
                min-width: 90px;
                margin-bottom: 0;
            }
        }

        &Caption {
            color: #748cab;
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
            text-transform: uppercase;
            margin-bottom: 0;
            margin-right: 20px;
            text-align: left;
        }
    }

    &-StayConnectedWrapper {
        height: 127px;
        margin-top: 15px;
        text-align: center;

        @include mobile {
            height: 200px;
        }
    }

    &-Social {
        border-top: 1px dashed rgba(151, 151, 15, 0.42);

        div {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    &-Payments {
        @include desktop {
            float: right;
        }
    }

    &-SocialIcons {
        @include desktop {
            float: left;
        }
    }

    &-Logo {
        margin-top: 5px;
        width: 60px;
        height: 40px;
        display: inline-block;

        &_visa {
            background: url($pathToFooterIcons + 'paymentlogo.png') -2px -6px;
        }

        &_mastercard {
            background: url($pathToFooterIcons + 'paymentlogo.png')  -76px -6px;
        }

        &_discover {
            background: url($pathToFooterIcons + 'paymentlogo.png')  -226px -10px;
        }

        &_amax {
            background: url($pathToFooterIcons + 'paymentlogo.png')  -306px -10px;
        }

        &_paypal {
            background: url($pathToFooterIcons + 'paymentlogo.png')  -152px -6px;
        }
    }

    &-Link {
        margin-top: 10px;
        cursor: pointer;
        margin-right: 15px;
        max-width: 32px;
        display: inline-block;

        &_facebook {
            width: 12px;
            height: 27px;
            background: url($pathToFooterIcons + 'social_icons.png') -10px -10px;
        }

        &_twitter {
            width: 30px;
            height: 26px;
            background: url($pathToFooterIcons + 'social_icons.png') -136px -10px;
        }

        &_pinterest {
            width: 28px;
            height: 28px;
            background: url($pathToFooterIcons + 'social_icons.png') -88px -10px;
        }

        &_instagram {
            width: 26px;
            height: 26px;
            background: url($pathToFooterIcons + 'social_icons.png') -42px -10px;
        }

        &_youtube {
            -webkit-filter: brightness(160%);
            filter: brightness(160%);

            img {
                width: 32px;
            }
        }

        &_houzz {
            img {
                width: 32px;
            }
        }
    }

    &-CopyrightContentWrapper {
        background-color: var(--footer-background-color);
    }

    &-CopyrightContent {
        padding: 10px 0;
        display: flex;
        justify-content: left;
        align-items: left;
        min-height: var(--footer-copyright-height);
        border-top: 1px dashed rgba(151,151,15,.42);

        @include after-mobile {
            padding: 15px 0;
        }
    }

    &-Copyright {
        font-size: 1.5rem;
        font-weight: 400;
        text-align: left;
        color: #ffff;
        a {
            color: var(--footer-font-color);
        }
    }

    &-Top {
        margin-bottom: 60px;
        min-height: 80px;
    }

    &-Content {
        min-height: var(--footer-content-height);
        background-color: var(--footer-background-color);
    }

    &-Columns {
        display: flex;
        height: 100%;
        padding: 25px 0px;
        justify-content: space-between;
        border-top: 1px dashed rgba(151,151,15,.42);

        @include mobile {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        @include after-mobile {
            padding: 60px 70px;
        }

        @include tablet {
            padding: {
                left: 30px;
                right: 30px;
            };
        }
    }

    &-Column {
        &:not(:last-of-type) {
            @include mobile {
                margin-bottom: 30px;
            }

            @include after-mobile {
                padding-right: 20px;
                max-width: calc(100% / #{ $column-count - 1 });
            }
        }
    }

    &-ColumnTitle {
        margin: 0 0 15px;
        font-size: 1.7rem;
        color: #fff;

        @include after-mobile {
            margin: 0 0 20px;
        }
    }

    &-ColumnContent {
        display: flex;
        flex-direction: column;

        &_direction_horizontal {
            flex-direction: row;

            @include mobile {
                justify-content: center;
            }
        }

        a + a {
            margin-left: 0;
        }

        * {
            color: var(--footer-font-color);
        }
    }

    &-ColumnItem {
        color: var(--footer-font-color);
        margin-bottom: 8px;
        font-size: 1.4rem;
        text-decoration: underline;

        @include mobile {
            line-height: 49px;
            margin-bottom: 0;
        }

        &:last-of-type {
            margin: 0;
        }

        &:hover,
        &:active {
            color: var(--primary-base-color);
        }

        &_type_image {
            width: 25px;

            &:not(:last-of-type) {
                margin-right: 20px;
            }
        }
    }

    .ContactInfo {
        &-Address {
            line-height: 125%;
        }
        &-Number {
            text-decoration: underline;
            &:hover {
                color: var(--primary-base-color);
                text-decoration: none;
            }
        }
    }

    &-CmsBlockWrapper {
        .CmsBlock-Wrapper {
            flex: 1;
        }

        .ContentWrapper .Footer-Columns {
            padding: 0;
        }

        .Footer-Column_isNewsletter {
            @include desktop {
                padding-left: 100px;
            }
        }
    }
}
