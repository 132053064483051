@font-face {
    font-family: 'Lato';
    src: local('Lato'),
        url(/style/fonts/lato-regular-webfont.woff2) format('woff2'),
    url(/style/fonts/lato-regular-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next';
    src: local('Avenir Next'),
        url(/style/fonts/AvenirNextLTPro-Regular.woff2) format('woff2'),
    url(/style/fonts/AvenirNextLTPro-Regular.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

$font-lato-regular: 'Lato', sans-serif;
$font-avenir-next: 'Avenir Next', sans-serif;
