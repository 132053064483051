/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CarouselScrollArrow {
    $transform: translate(-50%, -50%);
    $transform-hover: $transform scale(1.2);

    height: 40px;
    z-index: 1;
    transition: opacity 200ms;
    transform: translateY(50%);
    position: absolute;
    top: -70px;
    right: 20px;

    &::before {
        content: '';
        position: absolute;
        left: -20px;
        top: 50%;
        transform: $transform rotate(45deg);
        transition: transform 200ms;
        display: block;
        height: 10px;
        width: 10px;
        border-style: solid;
        border-width: 0 0 2px 2px;
        border-color: #E53F25;;
    }

    &:hover {
        &::before {
            transform: $transform-hover rotate(45deg);
        }
    }

    &_isNextArrow {
        right: -5px;
        &::before {
            transform: $transform rotate(-135deg);
        }

        &:hover {
            &::before {
                transform: $transform-hover rotate(-135deg);
            }
        }
    }

    &_isDisabled {
        cursor: default;
        pointer-events: none;
        &::before {
            border-width: 0 0 1px 1px;
            border-color: #D2D2D2;
        }
    }
}
