/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --dropdown-my-account-menu-bg-color: #e7e3e3;
}

.MyAccountTabListDropdown {
    position: absolute;
    z-index: 110;
    background-color: white;
    top: calc(var(--header-top-menu-height));
    right: -30px;
    padding: 0;
    display: none;
    border: 1px solid #cac5c5;

    &_expanded {
        display: block;
    }

    ul {
        &::before {
            position: absolute;
            top: -15px;
            right: 17px;
            border-right: 15px solid transparent;
            border-bottom: 15px solid #d3d3d3;
            border-left: 15px solid transparent;
            border-bottom-color: white;
            content: '';
        }

        li {
            button {
                &::after {
                    content: none;
                }
            }

            &:last-child {
                background-color: #{$custom-highlighted-color};
                padding-top: 10px;
                padding-bottom: 10px;

                button {
                    text-align: center;
                    color: white;

                    &:hover,
                    &:active {
                            background-color: #{$custom-highlighted-color};
                            color: white;
                            text-shadow: none;
                        }

                    &::after {
                        content: none;
                    }
                }
            }
        }
    }

    .MyAccountTabListItem {
        &-Button {
            &:hover,
            &:active {
                    background-color: var(--dropdown-my-account-menu-bg-color);
                    color: #{$custom-highlighted-color};
                }
        }
    }
}
