/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';


:root {
    --search-overlay-light-gray-color: #eaeaea;
    --black: #0b0204;
    --search-sku-color: #999;
    --search-product-description-color: #888888;
    --search-second-background: #f8f8f8;
}

.SearchSpringOverlay:not(.SearchSpringOverlay_isStatic) {
    @include mobile {
        max-height: 100%;
        padding-bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        top: calc(var(--header-total-height) - 44px);
        z-index: 101;
    }
}

.SearchSpringOverlay {
    box-shadow: 0px 8px 17px 0px #00000036;

    @include after-mobile {
        border-color: transparent;
        border-style: solid;
        border-width: 0 1px 1px;
        max-height: 52vh;
        max-width: calc(var(--search-bar-max-width) + 2px);
        right: 0;
        text-align: left;
        top: 100%;
        transform-origin: 0 0;
        transform: scaleY(0);
        transition-duration: 200ms;
        transition-property: width, opacity, transform, border-color;
        width: 100%;
        will-change: width, transform, border;
    }

    &_isVisible {
        overflow-y: auto;

        @include after-mobile {
            border-color: var(--search-field-border-color);
            overflow-y: auto;
            transform: scaleY(1);
            width: 100%;
        }
    }

    &-AllResults {
        text-align: center;
        position: -webkit-sticky; /* Safari */
        position: sticky;
        bottom: 0;
        height: 20px;
        margin-top: 2rem;
        text-decoration: underline;

        a {
            font-size: 1.2rem;
        }

        @include desktop {
            background-color: white;
        }
    }

    &-Results {
        border-top: 4px solid var(--default-green-color);

        @include desktop {
            grid-template-columns: 60% 40%;
            display: grid;
        }

        @include mobile {
            grid-template-columns: 100%;
            display: block;
            max-height: calc(100vh - 150px);

            h5 {
                font-size: 1.2rem;
            }

            a, div {
                font-size: 1.2rem;
            }
        }
    }

    &-ResultsColumnHeading {
        color: var(--search-product-description-color);
        margin-bottom: 1rem;
        text-transform: uppercase;
        font-size: 1.2rem;
        padding-left: 25px;
    }

    &-ResultsColumnSuggestedQuery {
        font-size: 1.2rem;
        padding: 5px 0 0 25px;
        color: black;
        display: block;
        margin-left: 0;

        &_isBold {
            font-weight: bold;
        }
    }

    &-ResultsColumnWrapper {
        display: flex;
        justify-content: space-between;
        margin-right: 25px;
    }

    &-ResultsColumn {
        background-color: white;

        &:nth-child(2) {
            background-color: var(--search-second-background);
            padding: 28px 25px;
        }

        &_Products {
            .Image {
                width: 60%;
                height: 60%;
                margin: auto;
            }

            @include mobile {
                display: grid;
                grid-template-columns: 100%;
            }
        }

        &_ProductResults {
            display: grid;
            grid-template-columns: 100%;
            grid-row: 1;

            @include desktop {
                border-left: 1px solid var(--secondary-dark-color);
                grid-row: auto;
                grid-template-columns: 50% 50%;
            }
        }

        &_notFound {
            background-color: var(--search-overlay-light-gray-color);
            grid-template-columns: 100%;
        }
    }

    &-Section {
        &-Title {
            color: var(--search-product-description-color);
            margin-bottom: 1rem;
            text-transform: uppercase;
            font-size: 1.2rem;
        }

        &-Content {
            margin-bottom: 1rem;
        }

        &-Link {
            color: var(--black);
            display: block;
            margin: 0;
            padding: .5rem;
            font-size: 1.2rem;
            font-weight: bold;

            &:hover,
            &:focus {
                background-color: var(--search-product-description-color);
                text-decoration: none;
                cursor: pointer;
            }

            &-Small {
                color: var(--light-grey-color);
                display: block;
                font-size: .8rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                b {
                    color: var(--black);
                }
            }
        }
    }

    &-ProductResults {
        &_notFound {
            padding: 1rem 25px;

            h4 {
                margin: 0;
                font-weight: normal;
            }

            p {
                margin: 2rem 0;

                a {
                    margin: 0;
                }
            }
        }

        &_highlight {
            margin: 0;
            font-weight: bold;
        }
    }

    &-NotFoundAccessorySuggestion {
        color: var(--search-product-description-color);
        padding-top: 10px;
    }

    &-ProductResult {
        padding: 25px 0 16px 25px;
        transition: background-color 200ms;

        @include mobile {
            padding: 6px 0 6px 25px;
        }

        &:hover {
            background-color: var(--search-second-background);
        }

        a {
            color: var(--black);
            display: grid;
            grid-template-columns: 100%;
            height: 100%;

            &:hover {
                text-decoration: none;
            }

            .Image {
                display: flex;
                align-self: center;
            }
        }
    }

    &-Content {
        align-self: center;
        display: flex;
        flex-direction: column;
        height: inherit;
        justify-content: space-evenly;
        padding-right: 5px;

        @include desktop {
            align-self: baseline;
        }

        span {
            margin-bottom: .3rem;
        }

        &-Category {
            color: var(--secondary-dark-color);
            display: block;
            font-size: 1rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &-Price {
            .Price {
                &-Current {
                    color: red;
                    margin-right: 0.5rem;
                }

                &-Original {
                    color: var(--secondary-dark-color);
                    font-size: 0.9rem;
                    text-decoration: line-through;
                }
            }
        }

        &-ProductSku {
            font-weight: 500;
            color: var(--search-sku-color);
        }
    }

    &-Refinements {
        background-color: var(--secondary-light-color);
        padding: 1rem;
        text-align: center;

        @include desktop {
            grid-column: span 2;
        }

        span {
            a {
                font-weight: 700;
                margin: 0;
            }
        }
    }

    &-ImageContentWrapper {
        display: flex;
        column-gap: 16px;
    }

    &-ProductImageWrapper {
        width: 80px;
        height: 80px;
        min-width: 80px;

        .Image {
            width: 100%;
            height: 100%;
        }
    }

    &-ProductBrandAndWidth {
        font-weight: normal;
        color: var(--search-product-description-color);
        margin: 6px 0;
    }


    &-CollectionName  {
        margin: 5px 0;
        font-weight: normal;
        color: var(--search-product-description-color);
    }

    &-CorrectedQueryDisclaimer {
        display: flex;
        padding: 25px 25px 0 25px;

        @include mobile {
            display: block;
        }
    }

    &-OriginalQuery {
        margin-left: 5px;
        text-decoration: underline;
        cursor: pointer;
        color: var(--link-color);

        @include mobile {
            margin-left: 0;
        }
    }

    .ProductCard-PriceWrapper_isLineThrough,
    .ProductCard-PriceWrapper {
        padding: 0;
    }

    .ProductCard-PriceBadgeWrapper {
        margin-left: 0;
    }
    
    .ProductCard-PriceWrapper {
        &-Price {
            &_isAccessory {
                display: block;
                margin-left: 5px;
            }

            &_isHidden {
                display: none;
            }
        }
    }
}
