/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --product-swatch-popup-price-color: #888888;
}

.ProductSwatchPopup {
    &-EmptySpace {
        position: fixed;
        height: 20vh;
        width: 100%;
        top: 0;
        left: 0;

        @include desktop {
            display: none;
        }
    }

    &-Popup {
        position: absolute;
        height: 522px;
        width: 343px;
        top: -210px;
        left: -100px;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        background-color: white;
        z-index: 10;
        border-radius: 8px;
        padding: 24px 24px 0 24px;
        opacity: 0;
        transition: opacity 1s ease-out;
        display: none;

        &_isOpen {
            opacity: 1;
            display: block;
            z-index: 12;
        }

        &_isPDP {
            width: 90%;
            top: -30px;
            left: 50%;

            @include desktop {
                transform: translateX(-50%);
            }
        }

        a {
            margin: 0;
        }

        @include mobile {
            position: fixed;
            height: 80vh;
            width: 100vw;
            top: 20%;
            left: 0;
            z-index: 100;
            box-shadow: 0px -150px 50px 25px rgba(0, 0, 0, 0.32);
            border-radius: 8px 8px 0 0;
        }
    }

    &-Content {
        max-height: 440px;
        overflow-y: auto;

        @include mobile {
            max-height: 68vh;
            padding-bottom: 170px;
        }
    }

    &-HeaderWrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    &-NumberOfSwatches {
        font-family: Lato;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
    }

    &-Close {
        float: right;
        cursor: pointer;
    }

    &-SwatchItemWrapper {
        width: 100%;
        height: 56px;
        box-sizing: border-box;
        border: 1px solid #f2f2f2;
        border-radius: 4px;
        padding: 12px;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 8px;

        &_isActive {
            border-color: black;

            .ProductSwatchPopup-SwatchItemPrice {
                color: black;
            }
        }

        &_isDisabled {
            color: var(--option-text-color);
            background-color: var(--option-background-color);
            text-decoration: line-through;
        }

        .Image_ratio_square {
            padding-bottom: unset;
        }
    }

    &-LinkWrapper {
        &_isDisabled {
            pointer-events: none;
            color: var(--option-text-color);
            background-color: var(--option-background-color);
            text-decoration: line-through;
        }
    }

    &-SwatchItemImage {
        margin-right: 8px;
        width: 40px;
        height: 32px;
        object-fit: cover;

        &_isHidden {
            display: none;
        }

        .Image-Image {
            object-fit: cover;
        }
    }

    &-SwatchItemLabel {
        color: black;
        font-size: 14px;
        font-weight: bold;
        text-align: left;
    }

    &-SwatchItemPrice {
        color: var(--product-swatch-popup-price-color);
        font-size: 14px;
    }

    &-SwatchItemPriceLabel {
        width: fit-content;
        background-color: black;
        color: white;
        font-size: 1.2rem;
        margin-top: 1px;
        line-height: 20px;
        font-weight: 400;
        padding: 0 8px;
        white-space: nowrap;
        height: fit-content;
    }

    &-SwatchItemInformation {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: fit-content;
        margin: auto;
    }
}
