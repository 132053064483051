/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MenuOverlay {
    display: none;
    &_isVisible {
        opacity: 1;
        display: block;

        @include mobile {
            -webkit-overflow-scrolling: touch;
            overflow-y: scroll;
        }
    }

    &:not(.Overlay_isStatic) {
        position: fixed;
        top: 130px;
        padding-bottom: 130px;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        z-index: 100;
        pointer-events: all;
        background-color: white;
    }
}
