/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.Breadcrumb {
    $crumb-padding: 10px;
    $arrow-size: 4px;

    display: inline-block;
    font-size: 1.2rem;
    line-height: 20px;
    padding: 0 $crumb-padding 0 0;

    @include mobile {
        font-size: 3vw;
    }

    &:not(:last-child) {
        margin: 0;

        &::after {
            content: '/';
            position: relative;
            display: inline-block;
            height: $arrow-size;
            width: $arrow-size;
            margin-left: $crumb-padding - 4;
            border-color: currentColor;
            border-width: 0 0 1px 1px;
        }
    }

    &:last-child {
        --breadcrumbs-color: var(--breadcrumbs-active-color);

        pointer-events: none;
        margin: 0;
    }

    &::before {
        content: none;
    }

    &-Link {
        text-transform: capitalize;
        color: var(--breadcrumbs-color);
        display: inline-block;

        @include mobile {
            height: 100%;
        }
    }
}
