/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 :root {
    --cart-overlay-totals-background: #f2f2f2;
    --cart-overlay-divider-background: #d8d8d8;
    --cart-overlay-promo-background: #fff;
    --cart-overlay-width: 400px;
    --cart-overlay-disclaimer-color: #9b9b9b;
    --cart-overlay-button-light-color: #{$custom-highlighted-color};
    --cart-overlay-desktop-border-color: #bbb;
}

.CartOverlay {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 0;

    @include desktop {
        position: fixed;
        right: 0;
        padding: 0;
        width: 400px;
        height: 100vh;
        top: 50px;
        z-index: 399;
    }

    @include mobile {

        &:not(.Overlay_isStatic) {
            z-index: 399;
            top: 0;
            overflow-y: auto;
            height: 100%;
            padding-bottom: 0;
        }
    }

    &_isVisible {
        top: 0;
        @include after-mobile {
            border-left: 1px solid var(--cart-overlay-desktop-border-color);
        }

        @include mobile {
            height: 100%;
            background-color: white;
            padding-top: 20px;
        }
    }

    &-Price {
        color: var(--primary-base-color);
    }

    &-Tax {
        color: #9b9b9b;
        font-size: 14px;
    }

    &-TitleAndCloseWrapper {
        display: flex;
        justify-content: space-between;
        margin: 0 15px;

        @include desktop {
            margin: 15px 25px;
        }
    }

    &-Title {
        color: #000000;
        font-family: Lato;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
        margin: 0;
    }

    &-CloseBtn {
        cursor: pointer;
    }

    &-EmptyWrapper {
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    &-Empty {
        color: #888888;
        font-family: Lato;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        margin: 0;
    }

    &-Actions {
        display: flex;
        width: 100%;
        padding: 1.2rem;
        border-top: 1px solid #F2F2F2;

        @include mobile {
            padding: 1.4rem;
        }
    }

    &-OutOfStockProductsWarning {
        padding: 1rem;
        display: flex;
        justify-content: center;
        background-color: var(--primary-error-color, red);
        font-size: 14px;
        font-weight: bold;
    }

    &-CheckoutButton {
        background-color: #000;
        border-radius: 4px;
        border-color: #000;
        color: #fff;
        font-family: Lato;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 16px;
        text-align: center;
        padding: 10px;
    }

    &-CartButton {
        background-color: #fff;
        border-radius: 4px;
        color: #000;
        text-transform: uppercase;
        font-family: Lato;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
        padding: 12px 10px;
        border-color: transparent;
        flex-grow: 1;
    }

    &-Promo {
        min-height: 4.8rem;

        @include mobile {
            min-height: 5.6rem;
        }

        @include after-mobile {
            min-height: 3.6rem;
        }
    }

    &-Promo {
        padding: 1.2rem;
        text-align: center;
        background: var(--cart-overlay-promo-background);
        margin-bottom: 0;

        @include mobile {
            padding: 1.4rem;
            border-bottom: 1px solid var(--cart-overlay-divider-background);
            border-top: 1px solid var(--expandable-content-color);
        }

        @include after-mobile {
            padding: .84rem;
            background: var(--primary-light-color);
        }

        strong {
            margin: 0 5px;
        }
    }

    &-Items {
        overflow-y: auto;
        margin-top: 10px;
        height: 100%;
    }

    &-Discount,
    &-Tax,
    &-Shipping {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.2rem 1.2rem .6rem;
        font-size: 1.8rem;
        background: var(--cart-overlay-totals-background);

        @include mobile {
            padding: 1.4rem 1.4rem .7rem;
        }

        @include after-mobile {
            font-size: 1.6rem;
        }

        dd {
            text-align: right;

            span {
                display: block;
                font-size: .6em;
                font-weight: 300;
            }
        }
    }

    &-SubTotalWrapper {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        color: #000000;
        font-family: Lato;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 17px;

        p {
            margin: 0;
        }
    }

    &-SubTotal {
        display: flex;
        align-items: center;
        font-weight: bolder;
    }

    &-Additional {
        width: inherit;
        padding: 10px;

        @include mobile {
            margin-top: 5px;
        }
    }

    &-TotalDisclaimer {
        font-style: italic;
        font-size: 1.4rem;
        font-weight: 700;
        color: var(--cart-overlay-disclaimer-color);
    }

    &-DiscountCoupon {
        &::after {
            content: ':';
            font-weight: normal;
        }
    }
}
