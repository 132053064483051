/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Znet, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 */


.LoginAccount {
    .ContentWrapper {
        min-height: auto;
        padding: 28px 20px 50px;

        @include desktop() {
            padding-top: 20px;
        }
    }

    &-InnerWrapper {
        @include desktop() {
            display: grid;
            justify-content: space-around;
            margin: 0 auto;
            padding-top: 0;
            grid-template-columns: 1fr 1fr;
            grid-gap: 100px;
        }

        .MyAccountOverlay {
            &-Additional {
                display: none;
            }
        }

        button {
            margin-bottom: 7px;

            @include desktop {
                margin-top: 20px;
            }
        }

        button,
        input {
            @include before-desktop {
                width: 100%;
            }

            @include desktop {
                max-width: 350px;
                width: 100%;
            }
        }

        > div {
            flex: 1;

            @include desktop {
                margin: 0 10px;
            }
        }

        h4 {
            padding-bottom: 7px;
            text-align: center;
        }

        p {
            margin-bottom: 20px;
        }
    }

    .Loader {
        margin: 0;
    }
}
