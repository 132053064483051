/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --get-sample-button-color: #{$default-secondary-light-color};
    --get-sample-text-color: #E53F25;
}

.GetSampleButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;

    &-AddToCart {
        &_isDisabled {
            pointer-events: none;
            opacity: 0;
        }
    }

    &-AddToCart,
    &-RemoveSample {
        display: flex;
        align-items: center;
        background-color: transparent;
        border-color: transparent;
        color: var(--get-sample-text-color);
        font-size: 14px;
        font-family: var(--product-actions-font-lato-regular);
        text-transform: none;
        font-weight: 400;
    }

    &-AddToCart:hover, &-AddToCart:active, &-AddToCart:focus {
        font-weight: 550;
        background-color: transparent!important;
        border-color: transparent!important;
        color: var(--get-sample-text-color)!important;
    }

    &-AddToCart,
    &-RemoveSample {
        margin: 0;
        padding: 0;
        background-color: unset;
        border-color: white;
        color: black;
        border-radius: 3px;
        font-size: 1.3rem;
        font-family: var(--product-actions-font-lato-regular);
        text-transform: none;
        font-weight: 400;
        display: flex;
    }

    &-RemoveSample {
        &_isRemovingProducts {
            pointer-events: none;
            opacity: 0.3;
            cursor: not-allowed;
        }
    }

    .Button:not([disabled]):hover,
    .Button:not([disabled]):focus {
        background-color: unset;
        border-color: white;
        color: black;
    }

    &-AddToCart:hover, &-AddToCart:active, &-AddToCart:focus {
        background-color: unset;
        border-color: white;
    }

    &-WishlistIcon {
        width: 20px;
        height: 20px;
        border: 1px solid var(--primary-base-color);
        border-radius: 5px;
        display: inline-block;
        margin-right: 3px;
    }

    &-WishlistIconPlus {
        font-size: 2rem;
        top: -4px;
        left: 3px;
        color: var(--primary-base-color);
    }
}
