/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/braintree-graphql
 * @link https://github.com/scandipwa/braintree-graphql
 */

.Braintree {
    &-Form {
        border-radius: 5px;
    }

    &-Checkbox {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        right: 1.2rem;

        .Field-Label {
            padding-top: .4rem;
            margin-left: .95rem
        }
    }

    [data-braintree-id="choose-a-way-to-pay"] {
        display: none;
      }
}

.CheckoutBilling_isPaymentError {
    .Braintree {
        .braintree-toggle {
            display: block;
        }
    }
}

.CheckoutPayments-PaymentErrorMessage {
    color: #CA2A2A;
    font-size: 13px;
    padding-top: 10px;
}
