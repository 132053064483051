/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

$pathToSocialLogo: './../../../public/assets/images/login-signup-block/';
:root{
    --social-authentication-title-background-color: #dbdbdb;
}

.SocialAuthentication {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0 1rem;
    margin-top: 0;
    justify-content: center;

    &-Wrapper {
        &_isCartPage {
            background-color: #FFFFFF;
            width: 100%;
            text-align: center;
            min-width: 200px;
            max-width: 1200px;
            margin-bottom: 20px;
            grid-column: 1 / span 2;
            padding: 15px;

            .SocialAuthentication {
                padding: 0;
                margin: 10px 0;
                justify-content: start;
            }
        }
    }

    &_isMyAccount {
        flex-direction: column;
    }

    &-Heading{
        text-align: center;
        background-color: var(--social-authentication-title-background-color);
        border-radius: 4px;
        padding: 10px 55px;
        margin-top: 50px;

        &_isCartPage {
            margin-top: 0;
            background-color: unset;
            padding: 0;

            p {
                text-align: start;
                color: black;
                font-size: 14px;
                font-weight: 600;
                line-height: 17px;
            }
        }

        p:first-child {
            margin-bottom: 0;
        }

        @include mobile {
            margin-bottom: 10px;
        }
    }

    &-Title {
        align-items: center;
        display: inline-flex;
        font-size: var(--color-social-auth-btn-title-mobile);
        padding: 18px 0 19px;
        white-space: nowrap;
        width: 100%;

        @include desktop {
            font-size: var(--color-social-auth-btn-title-desktop);
            padding: 26px 0 28px;
        }

        &::before,
        &::after {
            background: var(--primary-base-color);
            content: '';
            display: block;
            min-height: 1px;
            width: 40%;
        }

        &::after {
            margin-left: 10px;
        }

        &::before {
            margin-right: 10px;
        }

    }

    &-Item {
        align-items: center;
        display: flex;
        flex-direction: row;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        margin-bottom: 16px;
        padding: 0;

        &:last-of-type {
            margin-bottom: 0;
        }

        .Toggle {
            pointer-events: none;

            &-Label {
                margin: 0 20px 0 0;
            }

            label {
                order: 1;
            }
        }
    }

    &-ToggleWrapper {
        cursor: pointer;
    }

    .Button-Social {
        border: 2px solid black;
        border-radius: 50px;
        height: 50px;
        width: 50px;
        min-width: 50px;
        background: {
            position: center;
            repeat: no-repeat;
            size: 24px;
        }

        @include desktop {
            background-size: 26px;
            height: 40px;
            width: 40px;
            min-width: 40px;
        }

        &_type {
            &_facebook {
                background: {
                    image: url($pathToSocialLogo + 'facebook.svg');
                    size: 30px;
                }

                filter: grayscale(100%);

                @include desktop {
                    background-size: 36px;
                }
            }

            &_google {
                background: {
                    image: url($pathToSocialLogo + 'google.svg');
                }

                filter: grayscale(100%);

                @include desktop {
                    background-size: 22px;
                }
            }

            &_linkedin {
                background: {
                    image: url($pathToSocialLogo + 'linked-in.svg');
                    size: 20px;
                }

                @include desktop {
                    background-size: 24px;
                }
            }

            &_paypal {
                background: {
                    image: url($pathToSocialLogo + 'paypal.svg');
                    size: 20px;
                }

                @include desktop {
                    background-size: 21px;
                }
            }

            &_twitter {
                background: {
                    image: url($pathToSocialLogo + 'twitter.svg');
                    size: 34px;
                }

                filter: grayscale(100%);

                @include desktop {
                    background-size: 40px;
                }
            }

            &_instagram {
                background: {
                    image: url($pathToSocialLogo + 'instagram.svg');
                }

                @include desktop {
                    background-size: 28px;
                }
            }

            &_twitch {
                background: {
                    image: url($pathToSocialLogo + 'twitch.svg');
                    size: 20px;
                }

                @include desktop {
                    background-size: 26px;
                }
            }

            &_amazon {
                background: {
                    image: url('./../../../public/assets/images/login-signup-block/amazon.svg');
                }

                @include desktop {
                    background-size: 30px;
                }
            }
        }
    }
}
