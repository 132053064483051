/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --header-background: #f2f2f2;
    --header-color: #000;
    --header-contacts-color: #414141;
    --header-icon-stroke-width: 1px;
    --header-search-clear-background: #838383;
    --header-desktop-button-color: var(--primary-base-color);
    --header-height: 245px;
    --header-nav-height: 200px;
    --header-total-height:
        calc(
            var(--header-height)
            + var(--offline-notice-height)
            + var(--demo-notice-height)
            + env(safe-area-inset-top)
        );

    @include desktop {
        --header-dots-color: #000;
    }

    @include after-mobile {
        --header-nav-height: 110px;
        --header-height: calc(var(--header-nav-height) + var(--header-top-menu-height));
    }

    --header-mobile-link-color: #1D2E44;
    --header-top-menu-position: sticky;
}

@mixin button-invisible {
    opacity: 0;
    padding: 0;
    width: 0;
    pointer-events: none;
    overflow: hidden;
}

@mixin button-visible {
    opacity: 1;
    padding: 7.5px;
    width: 35px;
    pointer-events: all;
    overflow: visible;
}

@mixin logo-visible {
    opacity: 1;
    max-width: 100%;
}

.Header {
    --header-logo-width: 230px;
    --header-logo-height: 80px;

    left: 0;
    width: 100%;
    z-index: 100;
    background-color: var(--header-background);
    padding-top: env(safe-area-inset-top, 0);

    @include mobile {
        --header-nav-height: 140px;
        top: 0;
    }

    @at-root .hiddenHeader {
        @include before-desktop {
            /* stylelint-disable-next-line length-zero-no-unit */
            --header-height: 0px;
        }
    }

    &-MinicartQtyWrapper {
        display: none;

        &_isVisible {
            display: block;
            background-color: var(--primary-base-color);
            color: white;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            position: absolute;
            top: -4px;
            right: -8px;

            @include mobile {
                right: 5px;
            }
        }
    }

    &-SignIn{
        display: block;
        width: 49px;
        height: 26px;
    }

    &-MinicartQty {
        font-size: 12px;
        line-height: 1.5;
    }

    &_isHiddenOnMobile {
        @include before-desktop {
            pointer-events: none;
            opacity: 0;
        }
    }

    &-ContactUsLink {
        color: #1D2E44;
        font-family: Lato;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 13px;
    }

    &-BlogLink {
        color: #1D2E44;
        font-family: Lato;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 13px;
    }

    &-CallUs {
        color: #1D2E44;
        font-family: Lato;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 13px;
    }

    &-MobileLinks {
        display: flex;
        justify-content: flex-end;
        grid-area: signIn;
        text-align: center;
        margin-bottom: 5px;
    }

    &-PersonIcon {
        top: 2px;
        right: 5px;
        color: #000;
    }

    &-BagIcon {
        color: #000;
    }

    &-MinicartIcon {
        @include mobile {
            top: -20px;
            &:after {
                border-top: var(--header-icon-stroke-width) solid var(--header-color) !important;
                border-left: var(--header-icon-stroke-width) solid var(--header-color) !important;
                border-right: var(--header-icon-stroke-width) solid var(--header-color) !important;
                border-top-right-radius: 5px !important;
                border-top-left-radius: 5px !important;
                height: 8px !important;
                left: 4px !important;
                top: 8px !important;
                width: calc(6px - var(--header-icon-stroke-width)) !important;
            }
        }
    }

    &-Link {
        padding-right: 1.2rem;
    }

    &-Wishlist {
        width: 24px;
        height: 24px;

        .Icon {
            fill: #00000085;
        }
    }

    &_name {
        &_cart {
            .Header-Button_type_minicart {
                @include desktop {
                    --header-color: var(--primary-base-color);
                }
            }
        }

        &_cart_overlay {
            .Header-Button_type_minicart,
            .Header-MinicartTitle {
                @include after-mobile {
                    --header-color: var(--primary-base-color);
                }
            }

            .Header-MinicartItemCount {
                @include after-mobile {
                    color: var(--primary-base-color);
                }
            }
        }

        &_menu,
        &_menu_subcategory {
            .Header-Button_type {
                &_close {
                    @include desktop {
                        @include button-visible;
                    }
                }

                &_menu {
                    @include desktop {
                        @include button-invisible;
                    }
                }
            }
        }

        &_customer_account,
        &_customer_sub_account {
            .Header-Button_type_account,
            .Header-MyAccountTitle {
                @include desktop {
                    --header-color: var(--header-desktop-button-color);
                }
            }
        }

        &_checkout {
            .Header-Button,
            .Header-SearchWrapper,
            .SearchField {
                @include desktop {
                    display: none;
                }
            }
        }
    }

    &_isCheckout {
        .Header {
            &-Button_type_account {
                display: block;
            }

            &-Nav {
                @include desktop {
                    justify-content: flex-end;
                }
            }

            &-MyAccount {
                @include before-desktop {
                    position: absolute;
                    right: 10px;
                    width: auto;
                }
            }
        }

        .MyAccountOverlay {
            z-index: 999999;
            @include before-desktop {
                top: var(--header-total-height);
                height: calc(100vh - var(--header-total-height));
                position: fixed;
                width: 100vw;
            }
        }
    }

    &-Banner {
        background: var(--primary-base-color);
        color: $white;
        height: 50px;
        line-height: 50px;
        text-align: center;
        display: block;
        z-index: 100;

        strong {
            margin-left: 3px;
        }
    }

    &-MyAccountLink {
        height: var(--header-top-menu-height);

        strong {
            top: calc(50% - 15px/2);
            white-space: nowrap;
        }
    }

    &-Caret {
        border: solid #e53f25;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        margin-top: -6px;
        margin-left: 4px;
        // top: calc(50% - 8px);
    }

    &-TopMenu {
        position: var(--header-top-menu-position);
        top: var(--demo-notice-height);
        z-index: 398;
        display: flex;
        justify-content: center;
        background-color: #F2F2F2;
        padding: 0.6rem;
        min-height: 37px;

        @include mobile {
            min-height: 60px;
        }
    }

    &-TopClose {
        position: absolute;
        right: 15px;
        top: 10px;
        cursor: pointer;

        @include mobile {
            top: 4px;
            right: 4px;
        }
    }

    &-TopItem {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            margin: 0;
        }

        @include mobile {
            p {
                max-width: 80%;
                text-align: center;
            }
        }
    }

    /*Custom*/
    &-FlooringSamples {
        color: #1D2E44;
        font-family: Lato;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 13px;
        margin: 0;
    }

    &-SampleLink {
        white-space: pre-wrap;
        color: #1D2E44;
        font-family: Lato;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
    }
    &-RightMenu {
        display: flex;
        align-items: center;
        justify-content: center;
        div {
            &:not(.MyAccountTabListDropdown) {
                padding: 0px 15px 0px 15px;
            }
        }

    }
    /*End Custom*/

    &-Contacts {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        color: var(--header-contacts-color);

        span:first-child {
            margin-right: 20px;
        }
    }

    &-Nav {
        display: flex;
        align-items: center;
        max-width: 100%;
        height: var(--header-nav-height);
        background: #ffff;
        z-index: 20;

        @include mobile {
            height: calc(var(--header-nav-height) - 50px);
        }

        &_isCheckout {
            height: auto;
        }

        &-Container {
            align-items: center;
            width: 100%;
            max-width: var(--content-wrapper-width);
            margin: auto;
            height: var(--header-nav-height);
            padding-left: 1.2rem;
            padding-top: 1.2rem;
            padding-bottom: 1.2rem;

            @include desktop {
                justify-content: flex-start;
                display: flex;
                padding-right: 2.4rem;
            }

            @include mobile {
                display: grid;
                grid-template-areas:
                    "menu logo signIn"
                    "search search search"
                    "edit-ok cart share ";
                padding: 1.2rem 0.7rem;
                height: calc(var(--header-nav-height) - 50px);
            }

            &_isCheckout {
                justify-content: center;
                margin-top: 10px;
                padding-bottom: 0;

                @include mobile {
                    display: flex;
                    justify-content: space-between;
                    height: auto;
                    padding-left: 0;
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }

        @include mobile {
            padding: 0 1.4rem;
        }

        @include desktop {
            justify-content: flex-end;
        }
    }

    &-Button {
        @include button-invisible;

        cursor: pointer;
        margin: 0;
        height: 35px;

        &_isVisible {
            @include before-desktop {
                @include button-visible;
            }
        }

        &_isDisabled {
            pointer-events: none;
            opacity: .1;
        }

        &_type {
            &_menu,
            &_account {
                @include desktop {
                    @include button-visible;
                }
            }

            &_minicart {
                @include button-visible;

                @include mobile {
                    &:after,
                    &:before {
                        content: none !important;
                    }
                    grid-area: cart;
                }

                display: flex;
                justify-self: center;
                gap: 10px;
                width: 80px;
                margin-left: 20px;
            }

            &_close {
                @include desktop {
                    @include button-visible;
                }
            }

            &_back {
                @include back-button;
                display: none
            }

            &_menu {
                @include menu-button;

                @include desktop {
                    width: 100px;
                }
            }

            &_account {
                @include account-button;

                &.Header-Button_isVisible {
                    overflow: hidden;
                }
            }

            &_minicart {
                @include before-desktop {
                    @include minicart-button;
                }

                @include desktop {
                    cursor: default;
                    height: auto;
                    width: auto;
                }
            }

            &_searchClear {
                @include clear-search-button;
            }

            &_clear {
                @include desktop {
                    @include clear-button;
                }
            }

            &_edit {
                text-transform: uppercase;
                grid-area: edit-ok;

                &.Header-Button_isVisible {
                    width: 70px;
                    opacity: .5;
                }
            }

            &_share {
                @include share-button;
                grid-area: share;

                &.Header-Button_isVisible {
                    height: 0;
                    width: 0;
                    margin-left: 1rem;
                    opacity: .5;
                }
            }

            &_home {
                @include home-icon;
            }

            &_ok,
            &_cancel {
                font-size: 1.4rem;
                opacity: .5;
                grid-area: edit-ok;

                &.Header-Button_isVisible {
                    width: 70px;
                }
            }

            &_compare {
                @include button-visible;

                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &-CompareButtonWrapper {
        border-right: 2px dotted var(--header-dots-color);
        margin: 0 20px 0 7px;
        padding-right: 20px;
    }

    &-CompareIcon {
        @include compare-icon(var(--header-color));
    }

    &-MyAccount {
        &Wrapper {
            align-items: center;
            display: flex;
        }

        &Title {
            cursor: pointer;
            padding-right: .48rem;
            text-transform: uppercase;
            color: var(--header-color);

            @include mobile {
                padding-right: .56rem;
                display: none;
            }
        }
    }

    &-Minicart {
        &ButtonWrapper {
            align-items: center;
            cursor: pointer;
            top: 3px;

            @include tablet-portrait {
                margin-left: 0;
            }

            @include mobile {
                margin-left: 0;
                padding-right: 0;
                width: 49px;
                height: 26px;
            }
        }

        &Title {
            color: var(--header-color);
            margin-right: .84rem;
            text-transform: uppercase;

            @include mobile {
                margin-right: .98rem;
            }
        }

        &Icon {
            @include minicart-button;

            @include desktop {
                height: 20px;
                width: 20px;
                top: 1px;
            }
        }

        &ItemCount {
            line-height: 13px;
            min-width: 14px;
            padding-left: 5px;
            background: var(--primary-base-color);
            border-radius: 10px;
            color: var(--header-background);
            display: inline-block;
            font-size: 1.2rem;
            padding: 3px 5px 1px;
            position: absolute;
            transform: translateX(3px);
            z-index: 2;

            @include after-mobile {
                left: 100%;
                transform: translateX(-30px);
                top: 12px;
            }

            @include mobile {
                left: 50%;
                top: 18px;
            }
        }
    }

    &-LogoWrapper {
        transition-property: opacity;
        transition-duration: 200ms;

        @include desktop {
            @include logo-visible;
        }

        &_isVisible {
            @include logo-visible;
            margin-right: 15px;
        }

        @include mobile {
            text-align: center;
            grid-area: logo;
            margin: 0;
            min-width: 150px;
        }

        .Image-Image {
            object-fit: fill;
        }
    }

    &-Title {
        opacity: 0;
        max-width: 0;
        transition-property: opacity;
        transition-duration: 200ms;

        text-align: center;
        font-size: 1.56rem;
        color: var(--header-color);

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        pointer-events: none;

        position: absolute;
        margin: auto;
        width: 100%;
        height: 1.8rem;
        line-height: 1.8rem;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        @include mobile {
            font-size: 1.82rem;
            height: 2.1rem;
            line-height: 2.1rem;
        }

        &_isVisible {
            @include before-desktop {
                opacity: 1;
                max-width: calc(100% - 180px);
            }
        }
    }

    a {
        &:hover,
        &:focus {
            @include before-desktop {
                text-decoration: none;
            }
        }
    }

    &-Slider {
        @include desktop {
            max-width: 95%;
        }
    }
}
