
.Shipping {
    margin-top: 30px;
    margin-bottom: 45px !important;
    &-Card {
        background: #f2f2f2;
        border-right: 2px solid #fff;
        padding: 20px 10px;
        &-Title {
            color: #1c3141;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            text-transform: uppercase;
            max-width: 260px;
            min-height: 50px;
        }
        &-Action {
            color: var(--primary-base-color);
            font-size: 13px;
            font-weight: 800;
            line-height: 16px;
            &:hover {
                color: var(--primary-dark-color);
            }
        }
    }
}
