/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Popup .Popup-CloseBtn {
    top: 0;
}

.Popup_isVisible {
    height: 100vh;
    top: 0;
}

.LoginAccountPopup{
    input {
        border: 1px solid black;

        &:focus {
            box-shadow: 0px 0px 0px 1px black;
        }
    }

    .Field_isValid {
        input {
            border: 1px solid black;

            &:focus {
                border: 1px solid black;
                box-shadow: 0px 0px 0px 1px black, 0 0 0 30px var(--input-background-color) inset;
            }
        }
    }

    .Field_hasError {
        input {
            border: 1px solid var(--primary-error-color);

            &:focus {
                border: 1px solid var(--primary-error-color);
                box-shadow: 0px 0px 0px 1px var(--primary-error-color), 0 0 0 30px var(--input-background-color) inset;
            }
        }
    }
}

#sign-in-2 {
    input {
        border: 1px solid black;

        &:focus {
            border: 1px solid black;
            box-shadow: 0px 0px 0px 1px black;
        }
    }
}