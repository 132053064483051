/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --breadcrumbs-height: 40px;
    --breadcrumbs-background: #f2f2f2;
    --breadcrumbs-active-color: #404040;
    --breadcrumbs-color: #888;
}

.Breadcrumbs {
    background-color: var(--breadcrumbs-background);

    $crumb-padding: 20px;
    $arrow-size: 4px;
    z-index: 1;

    @include before-desktop {
        --breadcrumbs-background: #fff;
    }

    &-List {
        list-style: none;
        padding: 10px 0;
        height: 40px;
        white-space: nowrap;
        inline-size: max-content;
        width: 100%;
        overflow: hidden;

        @include mobile {
            height: 49px;
            padding: 0;
        }

        li {
            @include mobile {
                height: 100%;
            }
        }
    }
}
