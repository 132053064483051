/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --newsletter-subscription-input-background: var(--input-background-color);
    --newsletter-subscription-input-color: #{$black};
    --newsletter-subscription-placeholder-color: var(--secondary-dark-color);
    --newsletter-subscription-button-background: #{$custom-highlighted-color};
    --newsletter-subscription-button-background-hover: var(--primary-dark-color);
}

.NewsletterSubscription {
    min-width: 230px;
    padding: 0 1rem;

    @include desktop {
        padding: 0;
    }

    .FieldForm {
        padding-top: 60px;

        &-FieldWrapper {
            display: grid;
            row-gap: 1.5rem;

            @include mobile {
                margin-top: 20px;
            }

            @include small-mobile {
                margin-top: 20px;
                margin-left: -13px;
            }
        }

        &-Fieldset {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            &_isLoading {
                opacity: .7;
                pointer-events: none;
            }

            .Field {
                width: 100%;
                margin-top: 0;

                &-Message {
                    position: absolute;
                }

                input {
                    background-color: var(--newsletter-subscription-input-background);
                    color: var(--newsletter-subscription-input-color);
                    border: 1px solid transparent;
                    padding: 14px 12px;

                    &::placeholder {
                        color: var(--newsletter-subscription-placeholder-color);
                    }
                }

                &_hasError {
                    input {
                        border: 1px solid var(--primary-error-color);
                        border-right: 0;
                    }
                }
            }
        }

        &-Fields {
            margin-bottom: 0;
            min-width: 400px;

            @include mobile {
                min-width: auto;
            }
        }

        &-Button {
            width: 100px;
            height: 45px;
            margin-right: 10px;
            background-color: var(--newsletter-subscription-button-background);
            border-color: var(--newsletter-subscription-button-background);

            @include mobile {
                max-height: 49px;
                height: 48px;
                margin-right: 10px;
            }

            @include small-mobile {
                max-height: 49px;
                height: 48px;
                margin-right: 0;
                margin-bottom: 10px;
            }

            &:focus,
            &:hover {
                border-color: var(--newsletter-subscription-button-background-hover);
                background-color: var(--newsletter-subscription-button-background-hover);
            }
        }

        &-Title {
            margin-bottom: 10px;
            color: #fff;
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 1px;
            line-height: 24px;
            margin-right: 40px;
            white-space: nowrap;

            &-Caption {
                color: #748cab;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                text-transform: uppercase;
            }
        }
    }

    .Form {
        &_isInvalid {
            margin-bottom: 1.8rem;
        }
    }
}
