/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CarouselScroll {
    .CarouselScrollItem {
        display: flex;
        scroll-snap-align: start;
        border: solid 1px transparent;
        transition: border-color var(--animation-speed), filter var(--animation-speed);
        filter: opacity(.5);
        cursor: pointer;

        &_isActive {
            filter: opacity(1);
        }

        &:focus,
        &:hover {
            filter: opacity(1);
        }
    }

    &_dynamicItems {
        .CarouselScrollItem {
            display: flex;
            margin: 2px;
            height: 75px;
            width: 75px;
            scroll-snap-align: start;
            border: solid 1px transparent;
            transition: border-color var(--animation-speed), filter var(--animation-speed);
            filter: none;
            cursor: pointer;

            &:hover {
                filter: none;
            }

            .ProductCard {
                .equal-height-JlocK {
                    overflow: unset;
                }
            }

            &:nth-child(odd) {
                .ProductCard-AccessorySizeTooltip {
                    .Tooltip-Wrapper {
                        max-height: 150px;
                        top: unset;
                        bottom: 10px;
                        transform: translateX(-50%);
                        box-shadow: -5px -2px 15px #adadad;
                    }
                }
            }

            &:nth-child(even) {
                .ProductCard-AccessorySizeTooltip {
                    .Tooltip-Wrapper {
                        top: unset;
                        left: 0;
                        bottom: 10px;
                        transform: translateX(-90%);
                        box-shadow: -5px -2px 15px #adadad;
                    }
                }
            }

            &:nth-last-child(1) {
                .ProductCard-AccessorySizeTooltip {
                    .Tooltip-Wrapper {
                        top: unset;
                        left: 0;
                        bottom: 10px;
                        transform: translateX(-90%);
                        box-shadow: -5px -2px 15px #adadad;
                    }
                }
            }
        }
    }
}
