/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --cart-item-heading-color: #9b9b9b;
    --cart-item-remove-color: #748cab;
    --cart-item-delimiter-color: #ccc;
    --cart-item-background: #F2F2F2
}

.CartItem {
    border-bottom: none;

    @include mobile {
        border-bottom: 1px solid #E0E0E0
    }

    &:not(:first-child) {
        border-top: 1px solid var(--cart-item-delimiter-color);
    }

    &-Wrapper {
        &_isCart {
            background-color: white;
            padding: 1.2rem 0;
            grid: unset;
        }
    }

    &-DescriptionDeleteWrapper {
        display: flex;
        width: 100%;
        column-gap: 10px;
    }

    &-Picture {
        width: 100%;
        padding-bottom: 100%;

    }

    &-Options {
        display: block;
    }

    &-Option {
        color: var(--cart-item-heading-color);
        border: 0;
        text-transform: none;
        font-size: 1.2rem;
        font-weight: 500;
        padding: 0;
        margin: 0;
    }

    &-QuantityText {
        display: block;
        margin-right: 0;
    }

    &-QuantityWrapper {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        color: #000000;
        font-family: Lato;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 17px;

        @include mobile {
            margin-bottom: 0;
        }
    }

    &-Content {
        flex-direction: unset;

        &_isCheckout {
            display: block;
        }

        .CartItem_isEditing &:not(.CartItem-Content_isLikeTable) {
            @include after-mobile {
                padding-bottom: 10px;
            }
        }
    }

    &-Description {
        justify-content: space-between;
        width: 100%;
        min-height: 60%;
    }

    &-Color {
        color: #000000;
        font-family: Lato;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 17px;
    }

    &-Sku {
        color: #888888;
        font-family: Lato;
        font-size: 10px;
        letter-spacing: 0;
        line-height: 12px;
        text-align: right;
        margin-left: 7px;
    }

    &-BrandWidth {
        color: #888888;
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 17px;
    }

    &-CollectionName {
        color: #888888;
        font-family: Lato;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 15px;
    }

    &-Price {
        justify-content: flex-end;
        align-items: flex-end;

        @include mobile {
            span {
                font-size: 14px;
            }
        }
    }

    &-UnitPriceWrapper {
        display: inline-flex;
    }

    &-UnitPriceTitle {
        color: #888888;
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 17px;

        @include mobile {
            font-size: 12px;
        }
    }

    &-OneUnitPrice {
        color: #888888;
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 17px;

        @include mobile {
            font-size: 12px;
        }
    }

    &-PriceAndQuantityWrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &-TotalWrapper {
        display: flex;
        align-items: flex-end;

        color: #000000;
        font-family: Lato;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 17px;
        text-align: right;

        @include mobile {
            font-size: 12px;
            line-height: 14px;
        }
    }

    &-TotalTitle {

        @include desktop {
            padding-top: 4px;
        }
    }

    &-HeadingWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &_isCart {
            margin: 0;
        }

        &_isAccessory {
            display: block;

            span {
                margin: 0;
            }
        }
    }

    &-Coverage {
        color: #888888;
        font-family: Lato;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 15px;

        span {
            display: none;
        }

        &_isCart {
            font-size: 1.4rem;
            color: black;
            font-weight: 300;

            span {
                display: inline;
            }

            @include mobile {
                font-size: 12px;
            }
        }
    }

    &-Weight {
        p {
            font-size: 1.3rem;
            margin-bottom: 0;
            color: #888888;
        }
        
        &_isVirtual {
            display: none;
        }

        @include mobile {
            p {
                font-size: 12px;
            }
        }
    }

    &-WeightAttribute {
        p {
            font-size: 12px;
            margin-bottom: 0;
            color: #888888;
        }

        &_isCheckout {
            p {
                font-size: 1.3rem;
                margin-bottom: 0;
                color: black;
            }
        }

        &_isVirtual {
            display: none;
        }
    }

    &-AccessoriesButton {
        display: flex;
        align-items: center;
        grid-gap: 4px;
        gap: 4px;
        color: #E53F25;
        font-size: 14px;
    }

    &-Delete {
        margin-top: 30px;

        &:before,
        &:after {
            display: none;
        }

        @include mobile {
            display: block;
            max-width: none;
            position: relative;
            right: auto;
        }
    }

    &-DeleteButton {
        display: flex;
        align-items: flex-start;
        margin-top: 5px;
        margin-left: auto;

        &_isCart {
            margin: 0;
            align-items: center;
        }
    }

    &-MoveToWishlist {
        font-size: 1.4rem;
        height: 50px;
        line-height: 50px;
        text-align: center;

        span{
          display: inline-block;
          vertical-align: middle;
          line-height: normal;
          color: var(--link-color);
        }
    }

    & &-Qty { // hack to obtain required specificity
        margin-top: 0;
        display: flex;

        @include mobile {
            align-items: center;
            width: 90px;
        }

        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        margin-top: 16px;
        border: 1px solid var(--product-actions-input-border-color);
        border-radius: 4px;

        input {
            width: 36px;
            height: 17px;
            background: transparent;
            border: 0;
            font-weight: 400;
            text-align: center;
            padding: 0;
            order: 2;
            font-size: 1.6rem;
            margin: 0;

            &:focus,
            :hover {
                border: 0;
            }
        }

        button {
            position: relative;
            left: unset;
            right: unset;
            top: 0;
            border: 0;

            &:first-of-type {
                order: 3;
            }

            &:last-of-type {
                order: 1;
            }
        }
    }
}
