/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$white: #fff;
$black: #0a0a0a;
$default-primary-base-color: #e53f25;
$default-primary-dark-color: #cf3820;
$default-primary-light-color: #f69e77;
$default-secondary-base-color: #1d2d44;
$default-secondary-dark-color: #19263a;
$default-secondary-light-color: #738cab;
$default-tertiary-base-color: #f2f2f2;
$font-muli: 'Lato', sans-serif;
$font-avenir-next: 'Avenir Next', sans-serif;
$font-helvetica-neue: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-standard-size: 62.5%;
$custom-highlighted-color: #e53f25;
$close-button-color: rgba(180,180,180,1);

// File path:
$icons-path: '../../public/img/icon';
