/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --option-custom-margin: 5px;
    --option-font-lato-regular: #{$font-muli};
    --option-tooltip-color: #a2a2a2;

    --option-background-color: #f2f2f2;
    --option-check-mark-background: #{$black};
    --option-padding: 0 12px;
    --option-margin: 5px;
    --option-size: 60px;
    --option-text-color: #888888;
    --option-border-color: #cac5c5;

    @include mobile {
        --option-border-color: #{$black};
        --option-text-color: #{$black};
    }
}

.ProductAttributeValue {
    --button-background: initial;
    --button-color: initial;
    --button-padding: 0;
    --option-is-selected: 0;
    --wishlist-is-visible: none;


    &_isSelected {
        border: 1px solid var(--option-border-color);
    }

    &-Attribute-Wishlist {
        font-family: var(--option-font-lato-regular);
        font-size: 1.4rem;
        color: var(--option-tooltip-color);
        background-color: var(--option-background-color);
        position: absolute;
        z-index: 10;
        box-shadow: 0 0 14px 0 rgba(0,0,0,.08);
        width: 120px;
        padding: 10px 10px 5px;
        display: var(--wishlist-is-visible);
        transform: translate(-50%,-50%);
        top: 50%;
        left: 50%;
    }

    &-Wishlist-Image {
        width: 70%;
        max-width: 100%;
        max-height: none;
        margin: 5px 15%;
    }

    &-MediaImageLabel {
        display: block;
    }

    &-Color,
    &-String,
    &-Text {
        background-color: var(--option-background-color);
        border-color: var(--option-border-color);
        border-width: 1px;
        color: var(--option-text-color);
        display: inline-block;
        min-height: var(--option-size);
        width: var(--option-size);
        margin: var(--option-margin);
        padding: var(--option-padding);

        @include mobile {
            background-color: white;
        }
    }

    &-Image {
        background-color: var(--option-background-color);
        border-color: var(--option-border-color);
        border-width: 1px;
        color: var(--option-text-color);
        display: inline-block;
        min-height: var(--option-size);
        width: var(--option-size);
        margin: var(--option-custom-margin);
    }

    &-Image {
        padding: 0;
        position: relative;
        object-fit: cover;
    }

    &-Image-Overlay {
        --option-check-mark-background: white;

        position: absolute;
        top: 0;
        left: 0;
        margin-left: .6rem;

        @include mobile {
            margin-left: .7rem;
        }

        @include after-mobile {
            margin: 0;
        }
    }

    &-Color {
        margin-bottom: 0;
    }

    &-Color,
    &-Image-Overlay {
        font-size: 0;
        width: var(--option-size);

        box-shadow: inset 0 0 0 1px var(--option-border-color);
        will-change: box-shadow, background-color;

        &::before,
        &::after {
            content: '';
            position: absolute;

            opacity: var(--option-is-selected);
            transition: opacity 200ms;
            will-change: opacity, border-left, border-right;
        }

        &:hover {
            @include after-mobile {
                &::before,
                &::after {
                    --option-is-selected: .5;
                }
            }
        }
    }

    &-String,
    &-Text {
        border-style: solid;
        line-height: var(--option-size);
        min-width: calc(1.25 * var(--option-size));
        width: auto;
        text-align: center;

        &_isSelected {
            --option-border-color: var(--primary-base-color);
            --option-text-color: var(--primary-base-color);
        }
    }

    &-Text {
        display: flex;
        border: 0;
        align-items: baseline;
        margin: 0;
        line-height: 1.4;

        &_isSelected {
            color: var(--primary-base-color);
        }

        label,
        .input-control {
            font-weight: 400;
            cursor: pointer;
            margin: 0;
        }

        label {
            &:first-of-type {
                padding-left: 2.6rem;
                padding-bottom: 0;
                order: 1;
                text-align: left;
                line-height: 1.8;

                overflow: hidden;
                text-overflow: ellipsis;

                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;

                @include mobile {
                    padding-left: 3.2rem;
                }

                .input-control {
                    position: absolute;
                    left: 0;
                    margin: 0;
                    min-width: 1.8rem;
                    vertical-align: top;
                    border-color: var(--option-border-color);

                    @include mobile {
                        min-width: 2.1rem;
                    }
                }
            }
        }

        &:hover,
        &:focus {
            label {
                @include after-mobile {
                    border-color: var(--primary-base-color);
                }
            }

            .input-control {
                &::after {
                    @include after-mobile {
                        --checkmark-color: var(--secondary-dark-color);
                    }
                }
            }
        }

        @include after-mobile {
            padding: .6rem 0;
        }
    }

    &:hover {
        --wishlist-is-visible: block;
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &_isNotAvailable {
        pointer-events: none;
        div {
            color: var(--option-text-color);
            background-color: var(--option-background-color);
            border: 0;
            text-decoration: line-through;
        }
    }

    &-TextPills {
        height: 40px;
        padding: 12px;
        font-size: 1.2rem;
        color: black;
        border-radius: 5px;
        border: 1px solid black;
        margin-right: 8px;
        margin-bottom: 10px;

        &_isSelected {
            color: white;
            background-color: black;
        }
    }
}
