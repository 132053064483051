/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 :root {
    --slider-crumb-color: #d2d2d2;
    --slider-crumb-active-color: black;
}

.Slider {
    --translateX: 0;
    --translateY: 0;
    --animation-speed: 0;
    --slider-height: auto;
    --slide-height: 0;
    --slide-width: 0;
    --height-transition-speed: 0;

    max-width: 100%;
    overflow: hidden;
    touch-action: pan-y;

    transition: height var(--height-transition-speed);

    &-Wrapper {
        display: flex;
        align-items: flex-start;
        height: 100%;

        will-change: transform;
        transition: transform var(--animation-speed);
        transform: translate3d(var(--translateX), var(--translateY), 0);

        > * {
            flex-grow: 1;
            flex-shrink: 0;
            width: 100%;
            height: 100%;
        }

        &_isVertical {
            flex-direction: column;
        }
    }

    &-SingleCrumbBtn {
        width: 100%;
    }

    &-Crumbs {
        height: 49px;
        margin: auto;
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fit, 30px);
        justify-content: center;
    }

    &-Crumb {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: var(--slider-crumb-color);
        transition: background-color 300ms;

        &_isActive {
            background-color: var(--slider-crumb-active-color);
        }
    }
    &-Arrows {
        
        @include mobile {
            display: none;
        }

        position: absolute;
        top: calc(50% - 30px);
        width: 100%;

        &_isTopMenu {
            top: 20%;
            @include mobile {
                display: block;
                top: 32%;
            }
        }
        
        .Arrows{
            &-Right{
                right: 15px;
                position: absolute;
                cursor: pointer;
            }
            &-Left{
                left: 15px;
                position: absolute;
                cursor: pointer;
            }

            @include mobile {
                &-Right{
                    right: 22px;
                }
                &-Left{
                    left: 22px;
                }
            }
        }
    }
}
