/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --additional-description-color: #8b8b8b;
}


.Field {
    &_hasError {
        color: black;
        font-family: Lato;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.32px;
    }

    &_isValid {
        input {
            &:focus {
                border: 1px solid black;
                box-shadow: 0px 0px 0px 1px black;
            }

            border: 1px solid black;
        }
    }

    &-AdditionalDescription {
        color: var(--additional-description-color);
        margin: 10px 0 0 20px;
    }

    &-CheckboxLabel {
        display: inline;
    }

    &_type {
        &_checkbox {

            .input-control {
                margin-right: 1rem;

                @include mobile {
                    margin-right: 1.2rem;
                }
            }
        }
    }

    &-AddBtn,
    &-SubtractBtn {
        width: 49px;
        height: 49px;
    }

    &-Label {
        &_isRequired {
            &::after {
                content: '*';
                color: red;
            }
        }
    }

}
