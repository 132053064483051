.Advantages {
    &-Section {
        padding: 30px 0;
        &-Title {
            margin-top: 10px;
            color: #1d2d44;
            font-size: 23px;
            font-weight: 700;
            line-height: 29px;
            text-align: center;
        }
    }
    &-Subsection {
        &-Subtitle {
            color: #1d2d44;
            font-size: 13px;
            font-weight: 700;
            line-height: 23px;
        }
        &-Description {
            color: #000;
            font-size: 13px;
            line-height: 23px;
        }
    }
}
