/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.DemoNotice {
    height: auto;
    position: var(--header-top-menu-position);
    top: 0;
    z-index: 398;
    justify-content: center;
    background-color: red;
    color: white;
    padding: 0.6rem;
    font-size: 12px;
    text-align: center;

    a {
        color: white;
        text-decoration: underline;
    }

    @at-root .isDemoVisible {
        --demo-notice-height: 27px;

        @include mobile {
            --demo-notice-height: 42px;
        }
    }
}
