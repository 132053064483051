/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Tooltip {
    --tooltip-offset: auto;
    --tooltip-offset-left: 15px;

    display: inline;

    &-InfoClickableArea {
        display: inline-block;
        width: 40px;
        height: 30px;
    }

    &-InfoIcon {
        height: 18px;
        width: 18px;
        left: 6px;
        top: 3px;
        cursor: help;
        color: black;
    }

    &-Wrapper {
        left: var(--tooltip-offset-left);
        top: var(--tooltip-offset-top);
        height: fit-content;
        width: 190px;
        min-width: unset;
        max-height: 200px;
        overflow-y: scroll;

        @include mobile {
            bottom: unset;
            right: unset;
            left: var(--tooltip-offset-left);
            transform: unset;
        }
    }

    &-Text {
        font-size: 1.4rem;
    }
}