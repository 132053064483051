/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CarouselScroll {
    --translateY: 0;
    --animation-speed: 300ms;
    --carousel-item-height: 0;
    width: 100%;

    display: flex;
    flex-direction: row;

    &-ContentWrapper {
        overflow: hidden;
        flex: 1;
        display: flex;

        &_type_additional_pictures {
            margin-left: 15px;
            margin-right: 15px;
        }
    }

    &-Content {
        margin: auto;
        transform: translateX(var(--translateY));
        transition: transform var(--animation-speed);
        display: flex;
        flex-direction: row;

        &_type_additional_pictures {
            flex-direction: column;
            gap: 6px;
        }
    }

    &-Arrows {
        &_isCartPopup {
            @include mobile {
                display: none;
            }
        }
    }
}
