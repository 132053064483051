/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --menu-background-color: #fff;
    --menu-desktop-background-color: rgba(122, 122, 122, .44);
    --menu-desktop-border-color: rgba(255, 255, 255, .17);
    --menu-desktop-height: 54px;
    --menu-item-figure-background: var(--secondary-base-color);
    --menu-item-hover-color: var(--primary-base-color);
    --menu-second-level-color: #e8e8e8;
    --menu-active-color: #e53f25;
    --menu-mobile-display: block;
}

@mixin subcategory-visible {
    pointer-events: all;
    z-index: 10;
    opacity: 1;
    transform: translateX(0);
}


// sz-menu styling

.szh-menu-button {
    display: inline-block;

    a {
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: 400;
        color: #fff;
        line-height: 20px;
        padding: 15px 24px 16px;

        @include tablet {
            padding: 12px 10px;
        }
    }

    &:hover {
        background-color: var(--menu-active-color);
    }
}

.szh-menu-container	{
    margin-top: var(--menu-desktop-height);

    a {
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: 400;
        line-height: 20px;
        color: grey;
        padding: 12px 8px 12px 24px;
    }

    .szh-menu {
        padding: 0;
    }

    .szh-menu__submenu {
        padding-left: 0;
    }

    .szh-menu__submenu > .szh-menu__item {
        &:after {
            content: " ";
            float: right;
            border: solid #000;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
            transform: translateY(5px) rotate(45deg);
            -webkit-transform: translateY(5px) rotate(45deg);
            margin-left: 15px;
            margin-bottom: 10px;
        }
    }

    .szh-menu__item {

        &--hover {
            background-color: var(--menu-active-color);

            a {
                color: white;
            }

            &:not(.MenuItem_Active) {
                background-color: var(--menu-second-level-color);

                a {
                    color: grey;
                }
            }
        }
    }

    .SubMenu_Active {
        .szh-menu__item {
            a {
                color: grey;
            }
        }

        .MenuItem_Active {
            background-color: var(--menu-active-color);

            a {
                color: white;
            }
        }

        .szh-menu__item--submenu {
            background-color: var(--menu-active-color);

            a {
                color: white;
            }

        }
    }
}

.MenuButton_Active, .SubMenu_Active, .MenuItem_Active {
    background-color: var(--menu-active-color);

    a {
        color: white;
    }
}

.MenuButtonHasSubmenu {
    a {

        &:after {
            content: " ";
            float: right;
            border: solid white;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
            transform: translateY(5px) rotate(45deg);
            -webkit-transform: translateY(5px) rotate(45deg);
            margin-right: 15px;
            margin-bottom: 10px;
        }
    }
}

.Menu {
    &-MenuWrapper {
        background-color: var(--secondary-base-color);

        &_isMobile {
            position: relative;
            margin: 0 auto;
            overflow: hidden;
            background-color: #fff;
            position: relative;
            grid-area: menu;
            width: 30px;

            .Menu-Link {
                &:active {
                    background-color: red;
                    --menu-mobile-display: none;
                }
            }

            .Menu-SubmenuBlock {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #e8e8e8;

                .Link-Button {
                    padding: 0 0 0 15px;
                    display: block;
                    text-align: left;
                    line-height: 47px;
                    text-decoration: none;
                    color: #333;
                    cursor: pointer;
                    font-size: 1rem;
                    position: relative;
                    text-transform: uppercase;
                }
            }

            .Menu-ItemBlock {
                border-bottom: 1px solid #e8e8e8;

                .Link-Button {
                    padding: 0 0 0 15px;
                    display: block;
                    text-align: left;
                    line-height: 47px;
                    text-decoration: none;
                    color: #333;
                    cursor: pointer;
                    font-size: 1rem;
                    position: relative;
                    text-transform: uppercase;
                }
            }

            .Menu-SubmenuArrow {
                top: 5px;
                padding: 0 10px;
            }

            ul {
                list-style-type: none;
            }

            li {
                margin: 0;
                padding: 0;

                &:before {
                    content: none;
                }
            }


            nav {
                height: 50px;
                display: -ms-flexbox;
                display: -webkit-box;
                display: -webkit-flex;
                display: var(--menu-mobile-display);
                -webkit-align-items: center;
                align-items: center;
                /* fade in checked menu */
            }

            nav .Menu-MainMenuCheckbox {
                display: none;
            }

            nav label.Menu-CheckboxLabel {
                cursor: pointer;
                width: 49px;
                height: 49px;
            }

            nav .Menu-MobileSubmenu {
                position: absolute;
                top: 0;
                left: 0;
                max-width: 450px;
                width: calc(100vw - 30px);
                height: max-content;
                -moz-transform: translate3d(-450px, 0, 0);
                -o-transform: translate3d(-450px, 0, 0);
                -ms-transform: translate3d(-450px, 0, 0);
                -webkit-transform: translate3d(-450px, 0, 0);
                transform: translate3d(-450px, 0, 0);
                z-index: 399;
                overflow: hidden;
                background-color: #fff;
            }

            nav .FirstLevelMenu {
                top: calc(var(--header-top-menu-height) + var(--demo-notice-height));
            }

            nav .m-menu__overlay {
                background-color: rgba(103, 103, 103, 0.5);
                position: absolute;
                top: 0;
                width: 100%;
                bottom: 0;
                z-index: 1;
                display: none;
            }

            nav .Menu-MobileSubmenuHeader {
                padding: 0 16px;
                height: 50px;
                display: -ms-flexbox;
                display: -webkit-box;
                display: -webkit-flex;
                display: flex;
                -webkit-justify-content: space-around;
                justify-content: space-around;
                -ms-flex-pack: space-around;
                -webkit-align-items: center;
                align-items: center;
            }

            nav .Menu-MobileSubmenuHeader span {
                font-size: 1.2rem;
                font-weight: bold;
                text-align: center;
                width: 100%;
            }

            nav .Menu-MobileSubmenu .Menu-MobileSubmenu {
                -moz-transform: translate3d(480px, 0, 0);
                -o-transform: translate3d(480px, 0, 0);
                -ms-transform: translate3d(480px, 0, 0);
                -webkit-transform: translate3d(480px, 0, 0);
                transform: translate3d(480px, 0, 0);
            }

            nav .Menu-MobileSubmenu ul {
                height: 100%;
                overflow-y: auto;
                overflow-x: hidden;
            }

            nav .Menu-MobileSubmenu ul li a, nav .Menu-MobileSubmenu ul li label {
                display: block;
                text-align: left;
                line-height: 47px;
                text-decoration: none;
                color: #333;
                cursor: pointer;
                font-size: 1rem;
                position: relative;
                text-transform: uppercase;
            }

            nav .Menu-MobileSubmenu ul li a {
                padding: 0;
                padding-left: 15px;
                width: 100%;
            }

            nav .Menu-MobileSubmenu ul li .-invisible {
                border-bottom: 0;
            }

            nav .Menu-MobileSubmenu .Menu-MobileSubmenu label.Menu-CheckboxLabel {
                display: -ms-flexbox;
                display: -webkit-box;
                display: -webkit-flex;
                display: flex;
                border-bottom: 0;
                padding: 0;
                -webkit-box-sizing: content-box;
                -moz-box-sizing: content-box;
                box-sizing: content-box;
            }

            nav .Menu-MainMenuCheckbox:checked ~ .Menu-LabelOverlay {
                display: block;
            }

            nav .Menu-MainMenuCheckbox:checked ~ .Menu-MobileSubmenu {
                -moz-transform: translate3d(0, 0, 0);
                -o-transform: translate3d(0, 0, 0);
                -ms-transform: translate3d(0, 0, 0);
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
                width: 100vw;
                position: fixed;
                height: 100%;
            }

            nav .LinkCheckbox:checked {
                display: none;
            }

        }

        &:not(.Menu-MenuWrapper_isMobile) {
            min-height: var(--menu-desktop-height);
            padding: 0;
            display: flex;
            justify-content: center;

            ul {
                list-style-type: none;
            }

            li {
                height: 100%;
                margin: 0;

                &:before {
                    content: none;
                }

                &:not(.Menu-Item) {
                    &:nth-last-child(n+3) {
                        border-right: 1px solid rgba(255,255,255,.17);
                    }
                }

                @include mobile {
                    height: auto;
                }
            }



            nav {
                background-color: var(--secondary-base-color);
            }

            nav ul {
                padding: 0;
                margin: 0;
                list-style: none;
                position: relative;
                height: 100%;
            }

            nav ul li {
                display:inline-block;
                background-color: var(--secondary-base-color);

                &:hover {
                    background-color: var(--menu-active-color);
                }
            }

            nav a {
                display:block;
                padding:0 10px;
                font-size: 1.4rem;
                text-transform: uppercase;
                font-weight: 400;
                color: #fff;
                line-height: 20px;
                padding: 15px 14px 16px;
            }

            /* Hide Dropdowns by Default */
            nav ul ul {
                height: inherit;
                display: none;
                position: absolute;
            }

            /* Display Dropdowns on Hover */
            nav ul li:hover > ul {
                display:inherit;
            }

            /* Fisrt Tier Dropdown */
            nav ul ul li {
                width: 250px;
                float: none;
                display: list-item;
                position: relative;
                background-color: white;

                a {
                    color: gray;
                }

                &:hover {
                    background-color: var(--menu-second-level-color);
                }
            }

            /* Second, Third and more Tiers	*/
            nav ul ul ul li {
                position: relative;
                top: -51px;
                left: 250px;
            }


            /* Change this in order to change the Dropdown symbol */
            li > a:after {
                content: " ";
                border: solid #fff;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3px;
                transform: translateY(5px) rotate(45deg);
                -webkit-transform: translateY(5px) rotate(45deg);
                margin-left: 10px;
                position: inherit;
                bottom: 7px;
            }

            li > a:only-child:after {
                content: none;
            }

            li {
                padding-left: 0;
            }
        }
    }

    &-Overlay {
        background-color: var(--menu-desktop-background-color);
        height: 100vh;
        width: 100%;
        position: absolute;
        z-index: -1;
        top: -3px;
        left: 0;
        opacity: 0;
        transition: opacity 200ms ease-in;
        will-change: transition;

        &_isVisible {
            opacity: 1;
        }
    }

    &-MainCategories {
        @include before-desktop {
            display: flex;
            flex-direction: column;
        }

        @include desktop {
            display: block;
            padding-left: 1.2rem;
            padding-right: 40px;
            z-index: 100;
            max-width: var(--content-wrapper-width);
            width: 100%;
            margin: auto;
            pointer-events: none;
        }
    }

    &-ItemList {
        display: grid;

        @include desktop {
            display: flex;
        }

        &_type {
            &_main {

                a {
                    &:hover,
                    &:focus,
                    &:active,
                    .Menu-Link_isHovered {
                        color:#fff !important;
                    }
                }

                @include desktop {
                    pointer-events: auto;
                    width: min-content;
                }

                @include before-desktop {
                    order: 2;
                }
            }

            &_subcategory {
                background: var(--menu-background-color);
                grid-auto-rows: max-content;
                padding: 0 1.2rem;

                @include mobile {
                    padding: 0;
                }

                @include desktop {
                    // display: grid;
                    // grid-column-gap: 200px;
                    // grid-row-gap: 40px;
                    // grid-template-columns: minmax(200px, calc(100% - 40px) minmax(200px, calc(100% - 40px);
                    padding: 0;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                }
            }
        }
    }

    &-ItemCaption {
        text-transform: uppercase;

        &:not(.Menu-ItemCaption_type_main) {
            color: black;
            font-weight: 600;
            font-size: 1.6rem;
        }

        &_type {
            &_main {
                font-size: 1.8rem;
                font-weight: bold;
                line-height: 1.2;
                text-align: center;

                @include mobile {
                    font-size: 1.6rem;
                    text-align: left;
                }

                @include desktop {
                    font-size: 1.4rem;
                    font-weight: normal;
                }
            }

            &_subcategory {
                font-size: 1.2rem;
                font-weight: normal;

                @include mobile {
                    font-size: 1.4rem;
                }

                @include desktop {
                    padding-left: 0;
                }
            }
        }

        &_isLogo {
            font-size: 1.1rem;
        }

        &_isBanner {
            --button-background: var(--homepage-button-background);
            --button-border: transparent;
            --button-color: var(--homepage-button-color);
            --button-hover-background: var(--homepage-button-color);
            --button-hover-border: transparent;
            --button-hover-color: var(--homepage-button-background);
            --button-padding: 9px 49px;

            bottom: 20px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
        }
    }

    &-ItemArrow {
        &_type {
            &_main,
            &_subcategory {
                @include mobile {
                    &:not(.Menu-ItemArrow_noArrow) {
                        &::before {
                            content: '';
                            z-index: 10;
                            position: absolute;
                            left: 96%;
                            top: 50%;
                            transform: translate(-50%, -50%) rotate(-45deg);
                            transition: transform 200ms;
                            display: block;
                            height: 8px;
                            width: 8px;
                            border-style: solid;
                            border-width: 0 0 2px 2px;
                        }
                    }
                }
            }

            &_subcategory {
                color: black;
            }
        }

        &_isOpened {
            &::before {
                transform: translate(-50%, -50%) rotate(45deg) !important;
            }
        }
    }

    &-ItemFigure {
        align-items: center;
        display: grid;

        &_type {
            &_main {
                padding-left: 25px;
                text-transform: uppercase;

                @include before-desktop {
                    background-color: var(--menu-item-figure-background);
                    grid-auto-flow: column;
                    padding: 15px 30px;

                    &:hover,
                    &:focus,
                    &:active {
                        background-color: var(--primary-base-color);
                    }
                }

                @include desktop {
                    height: var(--menu-desktop-height);
                    padding-left: 0;
                    z-index: 20;
                }
            }

            &_subcategory {
                grid-template-columns: 4fr 2fr;
            }
        }

        @include mobile {
            &:not(.Menu-ItemFigure_type_main) {
                text-align: left;
                padding: 15px 30px 15px 40px;
                font-weight: 600;
            }
        }

        &_isBanner {
            @include desktop {
                overflow: hidden;
                width: 200px;
            }
        }

        &_isLogo {
            display: flex;

            .Image-Image {
                object-fit: cover;
            }
        }

        &_isHideOnDesktop {
            @include desktop {
                display: none;
            }
        }

        &_isActive {
            background-color: var(--menu-active-color);
        }
    }

    &-Item {
        width: 145px;
        text-align: center;
        color: #ffff;
        border-bottom: 1px solid white;
        cursor: pointer;
        &:hover,
        &:focus,
        &:active {
            background-color: var(--primary-base-color);
        }

        @include before-desktop {
            padding-left: 0;
        }

        @include mobile {
            padding-left: 0;
            width: 100%;
            margin-bottom: 1px;
        }

        @include desktop {
            margin-bottom: 0;
            padding: 0 2.4rem;
        }

        @include tablet-portrait {
            padding: 0 .5rem;
        }

        &:first-child {
            margin-top: 0;

            @include desktop {
                padding: 0;
            }
        }

        button {
            width: 100%;
        }

        &_isHideOnDesktop {
            @include desktop {
                display: none;
            }
        }
    }

    &-SubCategoriesWrapper {
        position: absolute;
        top: calc(var(--menu-desktop-height));
        pointer-events: none;
        width: 100%;

        @include tablet {
            top: var(--menu-desktop-height);
            left: 0;
        }

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: calc(var(--menu-desktop-height) * -1);
        }

        &_isVisible {
            pointer-events: all;
        }
    }

    &-SubCategoriesWrapperInner {
        background-color: var(--overlay-background);
        transition: clip-path 200ms ease-in;
        will-change: clip-path;
        clip-path: inset(0 0 100% 0);
        margin: auto;
        overflow-y: auto;
        max-height: calc(100vh - var(--header-nav-height));

        &_isVisible {
            clip-path: inset(0);
        }
    }

    &-SubCategories,
    &-Promotion {
        max-width: var(--content-wrapper-width);
        margin: auto;
    }

    &-Promotion {
        order: 3;
    }

    &-SubCategories {
        padding: 40px 55px 60px 1.2rem;

        @include mobile {
            padding: 40px 55px 60px 1.4rem;
        }
    }

    &-SubCatLink {
        &_isActive .Menu-ItemFigure_type_main {
            @include mobile {
                background-color: var(--primary-base-color);
            }
        }

        &_isActive {
            :first-child(.Menu-ItemArrow) {
                &::before {
                    transform: translate(-50%, -50%) rotate(45deg);
                }
            }
        }
    }

    &-SubMenu {
        background-color: var(--overlay-background);
        opacity: 0;
        pointer-events: none;
        touch-action: pan-y;
        transform: translateX(100%);
        transition: transform 200ms ease-in-out;
        display: none;

        @include before-desktop {
            height: auto;
            left: 0;
            transform: translateX(0%);
            width: 100%;
            background-color: white;
        }

        @include desktop {
            background: transparent;
            transform: translateX(0);
            transition-duration: 0ms;
            width: 100%;
        }

        &_isVisible {
            @include subcategory-visible;
            @include mobile {
                height: auto;
            }
            display: block;

            .Menu-Link {
                padding: 0;
            }


        }

        .Menu-Link {
            @include desktop {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    &-SubItemWrapper {
        flex-basis: 200px;

        @include desktop {
            margin-right: 4.8rem;
            margin-bottom: 2.4rem;
        }

        @include mobile {
            a {
                color: black;

                &:hover {
                    color: black;
                    background-color: var(--primary-base-color);
                }
            }
        }

        &_isBanner {
            flex-grow: 1;
            flex-basis: 400px;
        }

        &_isHideOnDesktop {
            @include desktop {
                display: none;
            }
        }

        > .Menu-Link {
            display: block;

            @include desktop {
                color: var(--menu-second-level-color);
                font-size: 1.4rem;
                text-transform: uppercase;
            }
        }
    }

    &-SubLevelDesktop {
        .Menu {
            &-ItemList {
                display: block;
                padding: 0;

                &_isBanner {
                    display: grid;
                    grid-column-gap: 10px;
                    grid-template-columns: repeat(auto-fit, 200px);
                }
            }

            &-Link {
                margin-top: 20px;
            }
        }
    }

    &-Image {
        &_isBanner {
            @include desktop {
                padding-bottom: 100%;
            }

            img {
                object-fit: cover;
            }
        }

        &_isLogo {
            height: 44px;
            margin-right: 20px;
            width: 44px;
        }

        &_type {
            &_main {
                grid-column: 2;
                height: 100%;
                justify-self: end;
                padding-bottom: 0;
            }

            &_subcategory {
                border-radius: 50%;
                height: 3.84rem;
                padding-bottom: 0;
                width: 3.84rem;

                @include mobile {
                    height: 4.48rem;
                    width: 4.48rem;
                }
            }
        }
    }

    &-Link {
        color: inherit;
        white-space: nowrap;
        padding-left: 0;

        @include before-desktop {
            padding: 1.2rem 0;
        }

        @include mobile {
            padding: 1.4rem 0;
        }

        @include desktop {
            display: block;
            margin-right: 0;
        }

        &:hover,
        &_isHovered,
        &:focus {
            color: var(--menu-item-hover-color);
            text-decoration: none;
        }
    }

    &-Link + &-Link {
        margin-left: 0;
    }

    .Image {
        background: none;
    }

    &-CompareLinkWrapper {
        text-transform: uppercase;
        order: 1;
    }

    &-CompareLink {
        color: var(--body-content-color);

        &:hover {
            text-decoration: none;
        }
    }
}
